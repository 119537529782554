import { FC } from 'react';

import { Container } from '@/shared';
import { Spacer } from '@house-booking/ui-kit';
import { BookingHistory } from '@widgets/Booking';
import { UserTabs, UserTabsEnum } from '@widgets/User';

export const HistoryView: FC = () => (
  <>
    <UserTabs tab={UserTabsEnum.History} />
    <Spacer size={24} />
    <Container>
      <BookingHistory />
    </Container>
  </>
);
