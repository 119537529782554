import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export interface ContainerProps {
  fluid?: boolean;
  className?: string;
}

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  fluid,
}): JSX.Element => (
  <div className={cx(className, styles.container, fluid && styles.fluid)}>
    {children}
  </div>
);
