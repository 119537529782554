import { FC } from 'react';
import { Redirect } from 'react-justanother-router';

import { RouterName } from '@app/router';
import {
  useIsAccountConfirmAdmin,
  useIsHouseEditor,
} from '@entities/Auth/libs/hooks';

export const AdminView: FC = () => {
  const isHouseEditor = useIsHouseEditor();
  const isAccountConfirmAdmin = useIsAccountConfirmAdmin();

  if (!isHouseEditor && !isAccountConfirmAdmin) {
    return <Redirect to={RouterName.Filter} />;
  }

  return (
    <Redirect
      to={
        isAccountConfirmAdmin
          ? RouterName.AdminConfirmationUsers
          : RouterName.AdminHouses
      }
    />
  );
};
