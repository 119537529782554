import { FC } from 'react';
import cx from 'classnames';

import { PropsWithNativeTypes, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface ListItemProps {
  label: string | JSX.Element;
  value: JSX.Element | number | string;
  row?: boolean;
}
export const ListItem: FC<
  PropsWithNativeTypes<ListItemProps, HTMLDivElement>
> = ({ className, label, row, value, ...rest }) => (
  <div
    className={cx(styles.listItem, row && styles.listItem_row, className)}
    {...rest}>
    <Typography
      tag="div"
      className={styles.listItem__label}
      variant="body-14"
      color="on-surface-primary-2">
      {label}
    </Typography>
    <Typography tag="div" variant="head-16" color="on-surface-primary-1">
      {value}
    </Typography>
  </div>
);
