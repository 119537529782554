import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
} from '@reduxjs/toolkit';
import { apiClient } from '@shared/api';

export const rootReducer = combineReducers({
  [apiClient.reducerPath]: apiClient.reducer,
});
export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
  configureStore({
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      apiClient.middleware,
    ],
    reducer: rootReducer,
    ...options,
  });

export const store = createStore();
