import { FC, useMemo, useState } from 'react';

import { Container, renderCellDate } from '@/shared';
import {
  CheckIcon,
  CloseIcon,
  LoaderOverlay,
  TableController,
} from '@house-booking/ui-kit';
import { sdk, Tariff } from '@shared/api';
import { formatAmount, pagination } from '@shared/libs';

import { AdminTariffListItemSettings } from '../AdminTariffListItemSettings/AdminTariffListItemSettings';

const headers = [
  { key: true, title: 'ID', value: 'id', width: 60 },
  { title: 'Дата создания', value: 'createdAt', width: 160 },
  { title: 'Дата обновления', value: 'updatedAt', width: 160 },
  { title: 'Название', value: 'name' },
  { title: 'Класс', value: 'class' },
  { title: 'Стоимость, ₽/день', value: 'cost_per_day' },
  { title: 'Скидка, %', value: 'discount_pct' },
  { title: 'Статус', value: 'available' },
  { title: ' ', value: 'settings', width: 48 },
];

export const AdminTariffsTable: FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isFetching } =
    sdk.useListTariffsWithFiltersAndPaginationTariffListGetQuery(
      pagination(page, pageSize),
    );

  const rowTemplate = (tariff: Tariff) => ({
    available: tariff.is_active ? (
      <CheckIcon color="var(--success-2)" />
    ) : (
      <CloseIcon color="var(--error-2)" />
    ),
    class: tariff.tariff_class || '-',
    cost_per_day: tariff.cost_per_day
      ? String(formatAmount(tariff.cost_per_day))
      : '-',
    createdAt: tariff.created_at
      ? renderCellDate({ time: true, timestamp: tariff.created_at })
      : '-',
    discount_pct: tariff.discount_pct ? `${tariff.discount_pct * 100}%` : '-',
    id: tariff.id!,
    name: tariff.tariff_name || '-',
    settings: (
      <AdminTariffListItemSettings
        id={tariff.id!}
        available={tariff.is_active}
      />
    ),
    updatedAt: tariff.updated_at
      ? renderCellDate({ time: true, timestamp: tariff.updated_at })
      : '-',
  });
  const items = useMemo(
    () => (data?.tariffs || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={isFetching} />
      <TableController
        data={items}
        headers={headers}
        total={data?.edge.total}
        countItems={[10, 25, 50]}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        pageSize={pageSize}
        counterPagination
        notResetPage
      />
    </Container>
  );
};
