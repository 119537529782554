import { useEffect } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { sdk } from '@shared/api';

export const LogoutView = () => {
  const [logout] = sdk.useLogoutAuthLogoutPostMutation();
  const signOut = useSignOut();
  const { navigate } = useNavigate();

  useEffect(() => {
    signOut();
    logout();
    navigate(RouterName.Login);
  }, []);

  return <></>;
};
