import { FC } from 'react';

import {
  useCancelBooking,
  useCreateOperation,
  useMakePayment,
} from '@entities/Booking/libs/hooks';
import { Button } from '@house-booking/ui-kit';
import {
  BookingResponse,
  BookingStatusEnum,
  HouseResponse,
  HouseStatusEnum,
  RemoteOperationTypeEnum,
} from '@shared/api';
import { isCurrentDateInRange, useModal } from '@shared/libs';

import styles from './styles.module.scss';

export interface BookingActionsProps {
  booking: BookingResponse;
  house: HouseResponse;
}
export const BookingActions: FC<BookingActionsProps> = ({ booking, house }) => {
  const openCancelBookingModal = useModal('cancelBooking');
  const cancelBooking = useCancelBooking();
  const createPayment = useMakePayment(booking.id!);
  const openHouse = useCreateOperation(
    house.id!,
    RemoteOperationTypeEnum.OpenHouse,
  );
  const closeHouse = useCreateOperation(
    house.id!,
    RemoteOperationTypeEnum.CloseHouse,
  );
  const inCurrentDate = isCurrentDateInRange(
    booking.start_date!,
    booking.end_date!,
  );
  const isPaid = booking.status === BookingStatusEnum.Paid;
  const canOpen =
    isPaid && inCurrentDate && house.status !== HouseStatusEnum.Open;
  const canClose =
    isPaid && inCurrentDate && house.status === HouseStatusEnum.Open;
  const canCancel = ![
    BookingStatusEnum.Pending,
    BookingStatusEnum.Completed,
    BookingStatusEnum.CanceledByUser,
    BookingStatusEnum.Refunded,
    BookingStatusEnum.CanceledByPaymentTimeout,
  ].includes(booking.status!);
  const canPay = booking.status === BookingStatusEnum.Pending;

  return (
    <div className={styles.bookingActions}>
      {canClose && <Button onClick={() => closeHouse()}>Закрыть дверь</Button>}
      {canOpen && <Button onClick={() => openHouse()}>Открыть дверь</Button>}
      {canPay && <Button onClick={() => createPayment()}>Оплатить</Button>}
      {canCancel && (
        <Button
          onClick={() =>
            openCancelBookingModal({
              onConfirm() {
                cancelBooking(Number(booking.id));
              },
            })
          }
          color="danger">
          Отменить бронирование
        </Button>
      )}
    </div>
  );
};
