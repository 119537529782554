import React, { FC } from 'react';

import { history, router, RouterName } from '@app/router';
import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@house-booking/ui-kit';

declare global {
  interface ModalProps {
    verifyAlert: void;
  }
}
export const VerifyAlertModal: FC<WithCloseModal> = ({
  onClose,
}): JSX.Element => (
  <Dialog
    title="Для совершения операций необходимо верифицировать аккаунт"
    closed
    width={480}
    footerSlot={
      <DialogFooter
        fullWidth
        startSlot={
          <Button color="secondary" fullWidth onClick={onClose}>
            Отмена
          </Button>
        }
        endSlot={
          <Button
            fullWidth
            onClick={() => {
              history.navigate(router.urlFor(RouterName.Profile));
              onClose();
            }}>
            Верифицировать
          </Button>
        }
      />
    }
  />
);
