import { FC } from 'react';
import cx from 'classnames';

import { Card } from '@/shared';
import { Typography } from '@house-booking/ui-kit';

interface HousesCountInfoProps {
  count: number;
  className?: string;
}
export const HousesCountInfo: FC<HousesCountInfoProps> = ({
  className,
  count,
}): JSX.Element => {
  const text = count ? `Найдено квартир: ${count}` : 'Не найдено квартир';

  return (
    <Card className={cx(className)}>
      <Typography variant="body-14">{text}</Typography>
    </Card>
  );
};
