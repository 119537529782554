import { FC } from 'react';
import cx from 'classnames';

import { Button, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export const AdminFooter: FC = () => (
  <div className={styles.footer}>
    <ul className={cx(styles.footer__list, styles.footer__list_desktop)}>
      <li>
        <Typography
          variant={'head-14'}
          color={'on-surface-primary-1'}
          className={styles.footer__link}>
          Оферта
        </Typography>
      </li>
      <li>
        <Typography
          variant={'head-14'}
          color={'on-surface-primary-1'}
          className={styles.footer__link}>
          Политика обработки данных
        </Typography>
      </li>
      <li>
        <Typography
          variant={'head-14'}
          color={'on-surface-primary-1'}
          className={styles.footer__link}>
          Условия использования
        </Typography>
      </li>
    </ul>
    <div className={styles.footer__list}>
      <Button variant={'outlined'}>Написать в поддержку</Button>
      <Typography variant={'body-14'} color={'on-surface-primary-1'}>
        © {new Date().getFullYear()} Smart Hotels
      </Typography>
    </div>
  </div>
);
