import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { AdminConfirmUser } from '@features/Admin';

export interface AdminUserConfirmationViewProps {
  params: {
    id: number;
  };
}
export const AdminUserConfirmationView: FC<AdminUserConfirmationViewProps> = ({
  params: { id },
}) => {
  const { navigate } = useNavigate();

  const handleCancel = () => {
    navigate(RouterName.AdminConfirmationUsers);
  };

  return (
    <div>
      <Head title="Подтверждение пользователя" onBack={handleCancel} />
      <AdminConfirmUser id={id} onCancel={handleCancel} />
    </div>
  );
};
