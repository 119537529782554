import { useEffect, useRef } from 'react';
import cx from 'classnames';
// eslint-disable-next-line import/default
import ymaps from 'yandex-maps';

import { Clusterer, Map as MapYandex, Placemark } from '@pbe/react-yandex-maps';
import { getCoords } from '@shared/libs/utils/format';

import styles from './styles.module.scss';

export interface Marker {
  latitude?: number;
  longtitude?: number;
  id?: number;
}
interface MapProps<T> {
  markers: T[];
  onMarkerClick?: (id: number) => void;
  className?: string;
}
export const Map = function <T extends Marker>({
  className,
  markers,
  onMarkerClick,
}: MapProps<T>): JSX.Element {
  const yandexMap = useRef<ymaps.Map>();
  const coords = markers.map((el) => getCoords(el));

  const handleSetCoords = () => {
    const bounds = yandexMap.current?.geoObjects.getBounds();

    if (coords.length > 1 || bounds) {
      yandexMap.current?.setBounds(bounds || coords, {
        checkZoomRange: true,
        preciseZoom: true,
        zoomMargin: [50],
      });
    }
  };

  useEffect(() => {
    handleSetCoords();
  }, [coords]);

  return (
    <MapYandex
      defaultState={{ center: coords[0] || [66, 94], zoom: 12 }}
      defaultOptions={{ autoFitToViewport: 'always' }}
      className={cx(styles.map, className)}
      onLoad={handleSetCoords}
      instanceRef={yandexMap}>
      <Clusterer
        options={{
          groupByCoordinates: false,
          preset: 'islands#redClusterIcons',
        }}>
        {markers.map((marker) => (
          <Placemark
            defaultGeometry={getCoords(marker)}
            key={marker.id}
            options={{
              preset: 'islands#redIcon',
            }}
            onClick={() => onMarkerClick && onMarkerClick(marker.id!)}
          />
        ))}
      </Clusterer>
    </MapYandex>
  );
};
