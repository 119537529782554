import { useEffect } from 'react';

import { toaster } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

export const useMakePayment = (bookingId: number) => {
  const [makePayment, { data, error }] =
    sdk.useMakeNewPaymentPaymentMakePaymentPostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Оплата прошла успешно' });
    }
    if (error) {
      toaster.error({ title: 'Ошибка оплаты' });
    }
  }, [data, error]);

  return () => makePayment({ bookingId });
};
