import { createElement } from 'react';
import {
  RendererFunction,
  RouteMatch,
  RouterEngine,
  RouterEngineInterface,
} from 'react-justanother-router';

import { routeMiddleware } from '@app/router/middleware';

import { routes } from './routes';

interface RenderRouteProperties {
  layout?: string;
}
export const router: RouterEngineInterface = new RouterEngine(routes);
export const renderer: RendererFunction<RenderRouteProperties> = (
  match,
): JSX.Element | null => {
  const props: RouteMatch = {
    params: match.params,
    query: match.query,
  };

  const redirect = routeMiddleware(match.props?.accessRole);

  if (redirect) {
    return redirect;
  }

  const target = createElement(match.component, props);

  if (match.props?.layout) {
    return createElement(match.props?.layout, props, target);
  }

  return target;
};
