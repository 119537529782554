import { FC } from 'react';

import { Container } from '@/shared';
import { Button, ButtonProps } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface ActionButton {
  label: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
  onClick(): void;
}
export interface ActionButtonsProps {
  buttons: ActionButton[];
}
export const ActionButtons: FC<ActionButtonsProps> = ({ buttons }) => (
  <div className={styles.actionButtons}>
    <Container className={styles.actionButtons__content}>
      {buttons.map((el, idx) => (
        <Button
          key={idx}
          color={el.color || 'secondary'}
          disabled={el.disabled}
          onClick={el.onClick}>
          {el.label}
        </Button>
      ))}
    </Container>
  </div>
);
