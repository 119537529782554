import { FC } from 'react';

import { Switch, Typography } from '@house-booking/ui-kit';
import { FilterData } from '@widgets/House';

import styles from './styles.module.scss';

interface HousesListTopFilterProps {
  onChange: (e: boolean, key: keyof FilterData) => void | undefined;
  form: FilterData;
}
export const HousesListTopFilter: FC<HousesListTopFilterProps> = ({
  form,
  onChange,
}): JSX.Element => (
  <div className={styles.topFilter}>
    <div className={styles.topFilter__switch}>
      <Typography variant="body-14">Показать только доступные дома</Typography>
      <Switch
        onChange={(e) => onChange(e.target.checked, 'showAvailable')}
        checked={form.showAvailable}
      />
    </div>
  </div>
);
