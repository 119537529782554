import { Route } from 'react-justanother-router';

import { Role, RouterName } from '@app/router';
import { HistoryDetailView, HistoryView, ProfileView } from '@pages/User';
import { BaseLayout } from '@widgets/Layout';

export const profileRoutes: Route = {
  children: [
    {
      component: ProfileView,
      name: RouterName.Profile,
      path: '/user',
      props: {
        accessRole: Role.USER,
        layout: BaseLayout,
      },
    },
    {
      children: [
        {
          component: HistoryDetailView,
          name: RouterName.HistoryDetail,
          path: '/:id',
          props: {
            accessRole: Role.USER,
            layout: BaseLayout,
          },
        },
      ],
      component: HistoryView,
      name: RouterName.History,
      path: '/history',
      props: {
        accessRole: Role.USER,
        layout: BaseLayout,
      },
    },
  ],
  path: '/profile',
};
