import { FC } from 'react';

import { Container } from '@/shared';
import { Spacer } from '@house-booking/ui-kit';
import { UserProfile, UserTabs, UserTabsEnum } from '@widgets/User';

export const ProfileView: FC = () => (
  <>
    <UserTabs tab={UserTabsEnum.Profile} />
    <Spacer size={24} />
    <Container>
      <UserProfile />
    </Container>
  </>
);
