import moment from 'moment';

export function isCurrentDateInRange(
  start_date: string,
  end_date: string,
): boolean {
  const currentDate = moment();
  const startDate = moment(start_date);
  const endDate = moment(end_date);

  return currentDate.isBetween(startDate, endDate, undefined, '[]');
}
