import { FC, useEffect, useState } from 'react';

import { ActionButtons } from '@/shared';
import { AdminHouseForm } from '@entities/Admin';
import { LoaderOverlay, toaster, Typography } from '@house-booking/ui-kit';
import { HouseCreate, HouseUpdate, sdk } from '@shared/api';
import { useModal } from '@shared/libs';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export interface AdminHouseEditProps {
  id: number;
  onCancel(): void;
}
export const AdminHouseEdit: FC<AdminHouseEditProps> = ({ id, onCancel }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const openActivateModal = useModal('activateHouse');
  const openDeactivateModal = useModal('deactivateHouse');
  const { data } = sdk.useGetHouseByIdHouseGetIdGetQuery({
    id: +id,
  });
  const [
    updateHouse,
    {
      data: resultUpdateData,
      error: errorUpdateData,
      isLoading: isLoadingUpdate,
    },
  ] = sdk.useUpdateHouseHouseUpdatePostMutation();
  const [updateData, setUpdateData] = useState<HouseUpdate>();
  const handleSave = () => {
    if (!updateData) {
      return toaster.error({ title: 'Данные для сохранения отсутствуют' });
    }
    updateHouse({ houseUpdate: updateData });
  };

  useEffect(() => {
    if (resultUpdateData) {
      toaster.success({ title: 'Дом успешно сохранён' });
    } else if (errorUpdateData) {
      toaster.error({
        contentSlot: isFetchBaseQueryError(errorUpdateData) ? (
          <Typography variant="body-14" color="on-surface-primary-2">
            {errorUpdateData.data.error_details}
          </Typography>
        ) : undefined,
        title: 'Ошибка сохранения дома',
      });
    }
  }, [resultUpdateData, errorUpdateData]);

  if (!data) {
    return <></>;
  }

  return (
    <div>
      <LoaderOverlay show={isLoadingUpdate} />
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          data.available
            ? {
                disabled: hasError,
                label: 'Снять с публикации',
                onClick: () => openDeactivateModal({ id }),
              }
            : {
                disabled: hasError,
                label: 'Опубликовать',
                onClick: () => openActivateModal({ id }),
              },
          {
            disabled: hasError,
            label: 'Сохранить изменения',
            onClick: handleSave,
          },
        ]}
      />
      <AdminHouseForm
        initialValues={data as HouseCreate}
        onChange={setUpdateData}
        onChangeHasError={setHasError}
      />
    </div>
  );
};
