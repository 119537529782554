import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { AdminHouseHeadInfo } from '@entities/Admin';
import { AdminTariffEdit } from '@features/Admin';
import { LoaderOverlay } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

export interface AdminTariffEditViewProps {
  params: {
    id: number;
  };
}
export const AdminTariffEditView: FC<AdminTariffEditViewProps> = ({
  params: { id },
}) => {
  const { data, isLoading } = sdk.useGetTariffByIdTariffGetIdGetQuery({
    id: +id,
  });
  const { navigate } = useNavigate();

  const handleCancel = () => {
    navigate(RouterName.AdminTariffs);
  };

  if (isLoading) {
    return <LoaderOverlay show />;
  }

  return (
    <div>
      <Head
        title="Редактирование тарифа"
        rightSlot={<AdminHouseHeadInfo available={!!data?.is_active} id={id} />}
        onBack={handleCancel}
      />
      <AdminTariffEdit id={id} onCancel={handleCancel} />
    </div>
  );
};
