import { FC } from 'react';

import { Card } from '@/shared';
import { HouseOperationItem } from '@entities/House';
import { LoaderOverlay, Spacer, Typography } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

import styles from './styles.module.scss';

export interface HouseOperationsProps {
  bookingId: number;
}
export const HouseOperations: FC<HouseOperationsProps> = ({ bookingId }) => {
  const { data: booking, isLoading: isLoadingBooking } =
    sdk.useGetBookingByIdBookingGetIdGetQuery({
      id: bookingId,
    });
  const { data, isLoading: isLoadingOperations } =
    sdk.useListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetQuery(
      {
        bookingId,
        houseId: Number(booking?.house_id),
      },
      { pollingInterval: 10_000, skip: !booking },
    );
  const isLoading = isLoadingBooking || isLoadingOperations;

  return (
    <Card className={styles.historyOperations}>
      <Typography variant="head-24" color="on-surface-primary-1">
        Последние операции
      </Typography>
      <Spacer size={4} />
      {isLoading ? (
        <LoaderOverlay />
      ) : data?.remote_operations?.length ? (
        <div>
          {data.remote_operations.map((operation, key) => (
            <HouseOperationItem
              key={`${key}+${operation.status}`}
              status={operation.status}
              date={operation.created_at}
              type={operation.operation_type}
            />
          ))}
        </div>
      ) : (
        <Typography
          className={styles.historyOperations__notFound}
          variant="head-16"
          color="on-surface-primary-2">
          Операции по дому отсутствуют
        </Typography>
      )}
    </Card>
  );
};
