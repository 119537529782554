import { useEffect } from 'react';

import { toaster, Typography } from '@house-booking/ui-kit';
import { sdk, SetPasswordUserSetPasswordPutApiArg } from '@shared/api';
import { useModal } from '@shared/libs';

export const useChangePassword = () => {
  const [changePassword, { data: changeData, error: changeError }] =
    sdk.useSetPasswordUserSetPasswordPutMutation();
  const openDialog = useModal('confirmChangePassword');
  const handleSubmit = (values: SetPasswordUserSetPasswordPutApiArg) => {
    openDialog({
      onSuccess() {
        changePassword({
          password: values.password,
          passwordConfirm: values.passwordConfirm,
        });
      },
    });
  };

  useEffect(() => {
    if (changeData) {
      toaster.success({ title: 'Пароль успешно изменён' });
    }
    if (changeError) {
      toaster.error({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Попробуйте позже или обратитесь к администратору
          </Typography>
        ),
        title: 'Ошибка смены пароля',
      });
    }
  }, [changeData, changeError]);

  return handleSubmit;
};
