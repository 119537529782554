import { FC, useEffect, useMemo, useState } from 'react';

import { Container, renderCellDate } from '@/shared';
import { HouseListFilter } from '@features/Admin';
import {
  CheckIcon,
  CloseIcon,
  LoaderOverlay,
  TableController,
} from '@house-booking/ui-kit';
import {
  HouseAdminViewResponse,
  ListHousesForAdminWithPaginationHouseAdminViewListGetApiArg,
  sdk,
} from '@shared/api';
import { pagination } from '@shared/libs';

import { AdminHouseListItemSettings } from '../AdminHouseListItemSettings/AdminHouseListItemSettings';

const headersDefault = [
  { key: true, title: 'ID', value: 'id', width: 60 },
  { title: 'Дата создания', value: 'createdAt', width: 160 },
  { title: 'Дата обновления', value: 'updatedAt', width: 160 },
  { title: 'Регион', value: 'region' },
  { title: 'Область', value: 'area' },
  { title: 'Блок', value: 'block' },
  { title: 'Комната', value: 'room' },
  { title: 'Статус', value: 'available' },
  { title: ' ', value: 'settings', width: 48 },
];
const headersAvailable = [
  { key: true, title: 'ID', value: 'id', width: 60 },
  { title: 'Дата создания', value: 'createdAt', width: 160 },
  { title: 'Дата обновления', value: 'updatedAt', width: 160 },
  { title: 'Регион', value: 'region' },
  { title: 'Область', value: 'area' },
  { title: 'Блок', value: 'block' },
  { title: 'Комната', value: 'room' },
  { title: 'Статус', value: 'available' },
  { title: ' ', value: 'settings', width: 48 },
];

export interface AdminHousesTableProps {
  filters?: HouseListFilter;
  onlyAvailable?: boolean;
}

export const AdminHousesTable: FC<AdminHousesTableProps> = ({
  filters,
  onlyAvailable,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getFilters = () => {
    if (filters?.id) {
      return { id: filters.id };
    }
    const resFilters: Partial<ListHousesForAdminWithPaginationHouseAdminViewListGetApiArg> =
      {};

    filters?.area && (resFilters.areaId = filters?.area);
    filters?.block && (resFilters.blockId = filters?.block);
    filters?.region && (resFilters.regionId = filters?.region);

    return resFilters;
  };

  useEffect(() => {
    setPage(1);
    setPageSize(10);
  }, [onlyAvailable]);

  const {
    data: dataAll,
    isFetching: isLoadingAll,
    isSuccess: isSuccessAll,
  } = sdk.useListHousesForAdminWithPaginationHouseAdminViewListGetQuery(
    { ...pagination(page, pageSize), ...getFilters() },
    { skip: onlyAvailable },
  );
  const {
    data: dataAvailable,
    isFetching: isLoadingAvailable,
    isSuccess: isSuccessAvailable,
  } = sdk.useListHousesForAdminWithPaginationHouseAdminViewListGetQuery(
    { ...pagination(page, pageSize), available: true, ...getFilters() },
    { skip: !onlyAvailable },
  );
  const isLoading = isLoadingAvailable || isLoadingAll;
  const data = useMemo(() => {
    const emptyData = {
      edge: {
        total: 0,
      },
      houses: [],
    };

    if (onlyAvailable) {
      return isSuccessAvailable ? dataAvailable : emptyData;
    }

    return isSuccessAll ? dataAll : emptyData;
  }, [dataAll, dataAvailable, onlyAvailable, isSuccessAvailable, isSuccessAll]);

  const headers = useMemo(() => {
    if (onlyAvailable) {
      return headersAvailable;
    }

    return headersDefault;
  }, [onlyAvailable]);

  const rowTemplate = (house: HouseAdminViewResponse) => ({
    area: house.area_id!,
    available: house.available ? (
      <CheckIcon color="var(--success-2)" />
    ) : (
      <CloseIcon color="var(--error-2)" />
    ),
    block: house.block_id!,
    createdAt: house.created_at
      ? renderCellDate({ time: true, timestamp: house.created_at })
      : '-',
    id: house.id!,
    region: house.region_id!,
    room: house.room!,
    settings: (
      <AdminHouseListItemSettings id={house.id!} available={house.available} />
    ),
    updatedAt: house.updated_at
      ? renderCellDate({ time: true, timestamp: house.updated_at })
      : '-',
  });
  const items = useMemo(
    () => (data?.houses || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={isLoading} />
      <TableController
        data={items}
        headers={headers}
        countItems={[10, 25, 50]}
        total={data?.edge.total}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        pageSize={pageSize}
        counterPagination
        notResetPage
      />
    </Container>
  );
};
