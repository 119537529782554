import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}
export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}
export const isSuccessResponse = (
  response: { data: any } | { error: any },
): response is { data: any } => 'data' in response;

export const isErrorResponse = (
  response: { data: any } | { error: any },
): response is { error: any } => 'error' in response;
