import { RoleEnum } from '@entities/Auth';
import { sdk } from '@shared/api';

import { useIsHouseEditor } from './useIsHouseEditor';

export const useIsAccountConfirmAdmin = () => {
  const isHouseEditor = useIsHouseEditor();
  const { data: user } = sdk.useMeUserMeGetQuery();

  return (
    isHouseEditor ||
    [RoleEnum.ACCOUNT_CONFIRMATION_ADMIN].includes(
      (user?.role || '') as RoleEnum,
    )
  );
};
