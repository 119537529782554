import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { ActionButtons } from '@/shared';
import { RouterName } from '@app/router';
import { AdminTariffForm } from '@entities/Admin';
import { LoaderOverlay, toaster, Typography } from '@house-booking/ui-kit';
import { sdk, TariffCreate } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export interface AdminTariffCreateProps {
  onCancel(): void;
}
export const AdminTariffCreate: FC<AdminTariffCreateProps> = ({ onCancel }) => {
  const { navigate } = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [
    createTariff,
    {
      data: resultCreateData,
      error: errorCreateData,
      isLoading: isLoadingUpdate,
    },
  ] = sdk.useCreateNewTariffTariffCreatePostMutation();
  const [createData, setCreateData] = useState<TariffCreate>();
  const handleSave = () => {
    if (!createData) {
      return toaster.error({ title: 'Данные для сохранения отсутствуют' });
    }
    createTariff({ tariffCreate: createData });
  };

  useEffect(() => {
    if (resultCreateData) {
      navigate(RouterName.AdminTariffs);
    } else if (errorCreateData) {
      toaster.error({
        contentSlot: isFetchBaseQueryError(errorCreateData) ? (
          <Typography variant="body-14" color="on-surface-primary-2">
            {errorCreateData.data.error_details}
          </Typography>
        ) : undefined,
        title: 'Ошибка создания тарифа',
      });
    }
  }, [resultCreateData, errorCreateData]);

  return (
    <div>
      <LoaderOverlay show={isLoadingUpdate} />
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          {
            disabled: hasError,
            label: 'Создать',
            onClick: handleSave,
          },
        ]}
      />
      <AdminTariffForm
        onChange={setCreateData}
        onChangeHasError={setHasError}
      />
    </div>
  );
};
