import { useContext } from 'react';

import { ModalContext, ModalContextType } from '@house-booking/ui-kit';

export const useModal = <T extends keyof ModalProps>(
  modalType: T,
  modalConfig?: ModalContextType['modalConfig'],
) => {
  const { showModal } = useContext<ModalContextType>(ModalContext);

  return (modalProps?: ModalProps[T]) => {
    showModal(modalType, modalProps, modalConfig!);
  };
};
