import { apiClient as api } from './client';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    activateUserUserVerifyPost: build.mutation<
      ActivateUserUserVerifyPostApiResponse,
      ActivateUserUserVerifyPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.userVerify,
        method: 'POST',
        url: `/user/verify`,
      }),
    }),
    addOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPost:
      build.mutation<
        AddOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPostApiResponse,
        AddOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPostApiArg
      >({
        query: (queryArg) => ({
          body: queryArg.coordinate,
          method: 'POST',
          params: { house_id: queryArg.houseId },
          url: `/house/add_or_update_coordinates`,
        }),
      }),
    addOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPost: build.mutation<
      AddOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPostApiResponse,
      AddOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.houseImageUrLlist,
        method: 'POST',
        params: {
          house_id: queryArg.houseId,
          is_updating: queryArg.isUpdating,
        },
        url: `/house/add_or_update_image_links`,
      }),
    }),
    addUserPersonalDataUserAddPersonalDataPost: build.mutation<
      AddUserPersonalDataUserAddPersonalDataPostApiResponse,
      AddUserPersonalDataUserAddPersonalDataPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.userPersonalDataCreate,
        method: 'POST',
        url: `/user/add_personal_data`,
      }),
    }),
    bindTariffsToHouseHouseBindTariffsPost: build.mutation<
      BindTariffsToHouseHouseBindTariffsPostApiResponse,
      BindTariffsToHouseHouseBindTariffsPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.tariffIds,
        method: 'POST',
        params: { house_id: queryArg.houseId },
        url: `/house/bind_tariffs`,
      }),
    }),
    cancelBookingByUserBookingCancelByUserPost: build.mutation<
      CancelBookingByUserBookingCancelByUserPostApiResponse,
      CancelBookingByUserBookingCancelByUserPostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { booking_id: queryArg.bookingId },
        url: `/booking/cancel_by_user`,
      }),
    }),
    cleanupUserUserDeleteUserIdDelete: build.mutation<
      CleanupUserUserDeleteUserIdDeleteApiResponse,
      CleanupUserUserDeleteUserIdDeleteApiArg
    >({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/user/delete/${queryArg.userId}`,
      }),
    }),
    createNewBookingBookingCreatePost: build.mutation<
      CreateNewBookingBookingCreatePostApiResponse,
      CreateNewBookingBookingCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.bookingCreateFromApi,
        method: 'POST',
        url: `/booking/create`,
      }),
    }),
    createNewHouseHouseCreatePost: build.mutation<
      CreateNewHouseHouseCreatePostApiResponse,
      CreateNewHouseHouseCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.houseCreate,
        method: 'POST',
        url: `/house/create`,
      }),
    }),
    createNewOperationRemOpsCreatePost: build.mutation<
      CreateNewOperationRemOpsCreatePostApiResponse,
      CreateNewOperationRemOpsCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.remoteOperationCreateFromApi,
        method: 'POST',
        url: `/rem_ops/create`,
      }),
    }),
    createNewRoleRoleCreatePost: build.mutation<
      CreateNewRoleRoleCreatePostApiResponse,
      CreateNewRoleRoleCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.roleCreate,
        method: 'POST',
        url: `/role/create`,
      }),
    }),
    createNewTariffTariffCreatePost: build.mutation<
      CreateNewTariffTariffCreatePostApiResponse,
      CreateNewTariffTariffCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.tariffCreate,
        method: 'POST',
        url: `/tariff/create`,
      }),
    }),
    createNewUserRoleBindingUserRoleAssignPost: build.mutation<
      CreateNewUserRoleBindingUserRoleAssignPostApiResponse,
      CreateNewUserRoleBindingUserRoleAssignPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.userRoleCreate,
        method: 'POST',
        url: `/user_role/assign`,
      }),
    }),
    createWebUserUserCreatePost: build.mutation<
      CreateWebUserUserCreatePostApiResponse,
      CreateWebUserUserCreatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.userCreateWeb,
        method: 'POST',
        url: `/user/create`,
      }),
    }),
    deleteBookingBookingDeletePost: build.mutation<
      DeleteBookingBookingDeletePostApiResponse,
      DeleteBookingBookingDeletePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/booking/delete`,
      }),
    }),
    deleteHouseHouseDeletePost: build.mutation<
      DeleteHouseHouseDeletePostApiResponse,
      DeleteHouseHouseDeletePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/house/delete`,
      }),
    }),
    deleteImagesImagesDeleteImagesPost: build.mutation<
      DeleteImagesImagesDeleteImagesPostApiResponse,
      DeleteImagesImagesDeleteImagesPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.imageUrlDataList,
        method: 'POST',
        url: `/images/delete_images`,
      }),
    }),
    deleteTariffTariffDeletePost: build.mutation<
      DeleteTariffTariffDeletePostApiResponse,
      DeleteTariffTariffDeletePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/tariff/delete`,
      }),
    }),
    getAllUserRoleUserRoleAllGet: build.query<
      GetAllUserRoleUserRoleAllGetApiResponse,
      GetAllUserRoleUserRoleAllGetApiArg
    >({
      query: () => ({ url: `/user_role/all` }),
    }),
    getBookingByIdBookingGetIdGet: build.query<
      GetBookingByIdBookingGetIdGetApiResponse,
      GetBookingByIdBookingGetIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/booking/get/${queryArg.id}` }),
    }),
    getCurrentUserRolesRoleMyRolesGet: build.query<
      GetCurrentUserRolesRoleMyRolesGetApiResponse,
      GetCurrentUserRolesRoleMyRolesGetApiArg
    >({
      query: () => ({ url: `/role/my_roles` }),
    }),
    getDocumentImagesImagesGetDocsImagesPost: build.mutation<
      GetDocumentImagesImagesGetDocsImagesPostApiResponse,
      GetDocumentImagesImagesGetDocsImagesPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.imageKeysList,
        method: 'POST',
        url: `/images/get_docs_images`,
      }),
    }),
    getHouseByIdHouseGetIdGet: build.query<
      GetHouseByIdHouseGetIdGetApiResponse,
      GetHouseByIdHouseGetIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/house/get/${queryArg.id}` }),
    }),
    getHouseByIdWithExtendedTariffInfoHouseGetExtIdGet: build.query<
      GetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetApiResponse,
      GetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/house/get_ext/${queryArg.id}` }),
    }),
    getHouseStatusByIdHouseGetStatusGet: build.query<
      GetHouseStatusByIdHouseGetStatusGetApiResponse,
      GetHouseStatusByIdHouseGetStatusGetApiArg
    >({
      query: (queryArg) => ({
        params: { house_id: queryArg.houseId },
        url: `/house/get_status`,
      }),
    }),
    getOperationStatusForHouseByUserRemOpsStatusGet: build.query<
      GetOperationStatusForHouseByUserRemOpsStatusGetApiResponse,
      GetOperationStatusForHouseByUserRemOpsStatusGetApiArg
    >({
      query: (queryArg) => ({
        params: { id: queryArg.id },
        url: `/rem_ops/status`,
      }),
    }),
    getPendingOperationsForUserRemOpsPendingGet: build.query<
      GetPendingOperationsForUserRemOpsPendingGetApiResponse,
      GetPendingOperationsForUserRemOpsPendingGetApiArg
    >({
      query: () => ({ url: `/rem_ops/pending` }),
    }),
    getRoleByNameRoleRoleNameGet: build.query<
      GetRoleByNameRoleRoleNameGetApiResponse,
      GetRoleByNameRoleRoleNameGetApiArg
    >({
      query: (queryArg) => ({ url: `/role/role/${queryArg.name}` }),
    }),
    getRolesRoleAllGet: build.query<
      GetRolesRoleAllGetApiResponse,
      GetRolesRoleAllGetApiArg
    >({
      query: () => ({ url: `/role/all` }),
    }),
    getTariffByIdTariffGetIdGet: build.query<
      GetTariffByIdTariffGetIdGetApiResponse,
      GetTariffByIdTariffGetIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/tariff/get/${queryArg.id}` }),
    }),
    getTariffsBoundToHouseHouseGetBoundTariffsGet: build.query<
      GetTariffsBoundToHouseHouseGetBoundTariffsGetApiResponse,
      GetTariffsBoundToHouseHouseGetBoundTariffsGetApiArg
    >({
      query: (queryArg) => ({
        params: { house_id: queryArg.houseId },
        url: `/house/get_bound_tariffs`,
      }),
    }),
    getUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGet:
      build.query<
        GetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetApiResponse,
        GetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetApiArg
      >({
        query: (queryArg) => ({
          params: { house_id: queryArg.houseId },
          url: `/booking/get_unavailable_dates_for_house`,
        }),
      }),
    getUserByEmailUserUserEmailGet: build.query<
      GetUserByEmailUserUserEmailGetApiResponse,
      GetUserByEmailUserUserEmailGetApiArg
    >({
      query: (queryArg) => ({ url: `/user/user/${queryArg.email}` }),
    }),
    getUserVerificationInfoByIdUserUserIdUserIdGet: build.query<
      GetUserVerificationInfoByIdUserUserIdUserIdGetApiResponse,
      GetUserVerificationInfoByIdUserUserIdUserIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/user/user_id/${queryArg.userId}` }),
    }),
    getUsersUserAllGet: build.query<
      GetUsersUserAllGetApiResponse,
      GetUsersUserAllGetApiArg
    >({
      query: () => ({ url: `/user/all` }),
    }),
    listAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGet:
      build.query<
        ListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetApiResponse,
        ListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetApiArg
      >({
        query: () => ({ url: `/house/list_all_locations_idents` }),
      }),
    listBookingsWithFiltersAndPaginationBookingListGet: build.query<
      ListBookingsWithFiltersAndPaginationBookingListGetApiResponse,
      ListBookingsWithFiltersAndPaginationBookingListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          duration_days: queryArg.durationDays,
          end_date: queryArg.endDate,
          house_id: queryArg.houseId,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          price: queryArg.price,
          start_date: queryArg.startDate,
          status: queryArg.status,
          tariff_id: queryArg.tariffId,
          user_id: queryArg.userId,
        },
        url: `/booking/list`,
      }),
    }),
    listHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGet:
      build.query<
        ListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetApiResponse,
        ListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetApiArg
      >({
        query: (queryArg) => ({
          params: {
            area: queryArg.area,
            block: queryArg.block,
            end_date: queryArg.endDate,
            max_cost_per_day: queryArg.maxCostPerDay,
            pager_offset: queryArg.pagerOffset,
            pager_size: queryArg.pagerSize,
            region: queryArg.region,
            start_date: queryArg.startDate,
          },
          url: `/house/list/available`,
        }),
      }),
    listHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGet:
      build.query<
        ListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetApiResponse,
        ListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetApiArg
      >({
        query: (queryArg) => ({
          params: {
            area: queryArg.area,
            block: queryArg.block,
            end_date: queryArg.endDate,
            max_cost_per_day: queryArg.maxCostPerDay,
            pager_offset: queryArg.pagerOffset,
            pager_size: queryArg.pagerSize,
            region: queryArg.region,
            start_date: queryArg.startDate,
          },
          url: `/house/list/available_ext`,
        }),
      }),
    listHousesForAdminWithPaginationHouseAdminViewListGet: build.query<
      ListHousesForAdminWithPaginationHouseAdminViewListGetApiResponse,
      ListHousesForAdminWithPaginationHouseAdminViewListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          area_id: queryArg.areaId,
          available: queryArg.available,
          block_id: queryArg.blockId,
          id: queryArg.id,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          region_id: queryArg.regionId,
        },
        url: `/house/admin_view_list`,
      }),
    }),
    listHousesToRepresentOnMapWithFiltersHouseListForMapGet: build.query<
      ListHousesToRepresentOnMapWithFiltersHouseListForMapGetApiResponse,
      ListHousesToRepresentOnMapWithFiltersHouseListForMapGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          area: queryArg.area,
          block: queryArg.block,
          end_date: queryArg.endDate,
          is_available: queryArg.isAvailable,
          max_cost_per_day: queryArg.maxCostPerDay,
          region: queryArg.region,
          start_date: queryArg.startDate,
        },
        url: `/house/list_for_map`,
      }),
    }),
    listHousesWithFiltersAndPaginationHouseListGet: build.query<
      ListHousesWithFiltersAndPaginationHouseListGetApiResponse,
      ListHousesWithFiltersAndPaginationHouseListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          area: queryArg.area,
          block: queryArg.block,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          region: queryArg.region,
        },
        url: `/house/list`,
      }),
    }),
    listHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGet:
      build.query<
        ListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetApiResponse,
        ListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetApiArg
      >({
        query: (queryArg) => ({
          params: {
            area: queryArg.area,
            block: queryArg.block,
            pager_offset: queryArg.pagerOffset,
            pager_size: queryArg.pagerSize,
            region: queryArg.region,
          },
          url: `/house/list_ext`,
        }),
      }),
    listRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGet:
      build.query<
        ListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetApiResponse,
        ListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetApiArg
      >({
        query: (queryArg) => ({
          params: {
            booking_id: queryArg.bookingId,
            house_id: queryArg.houseId,
            pager_offset: queryArg.pagerOffset,
            pager_size: queryArg.pagerSize,
          },
          url: `/rem_ops/list_for_user_repr`,
        }),
      }),
    listRemoteOperationsWithFiltersAndPaginationRemOpsListGet: build.query<
      ListRemoteOperationsWithFiltersAndPaginationRemOpsListGetApiResponse,
      ListRemoteOperationsWithFiltersAndPaginationRemOpsListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          booking_id: queryArg.bookingId,
          house_uid: queryArg.houseUid,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          status: queryArg.status,
          user_id: queryArg.userId,
        },
        url: `/rem_ops/list`,
      }),
    }),
    listTariffsWithFiltersAndPaginationTariffListGet: build.query<
      ListTariffsWithFiltersAndPaginationTariffListGetApiResponse,
      ListTariffsWithFiltersAndPaginationTariffListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          cost_per_day: queryArg.costPerDay,
          description: queryArg.description,
          discount_pct: queryArg.discountPct,
          is_active: queryArg.isActive,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          tariff_class: queryArg.tariffClass,
          tariff_name: queryArg.tariffName,
        },
        url: `/tariff/list`,
      }),
    }),
    listUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGet:
      build.query<
        ListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetApiResponse,
        ListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetApiArg
      >({
        query: (queryArg) => ({
          params: {
            pager_offset: queryArg.pagerOffset,
            pager_size: queryArg.pagerSize,
          },
          url: `/user/list_awaiting_confirmation`,
        }),
      }),
    listUsersWithFiltersAndPaginationUserListGet: build.query<
      ListUsersWithFiltersAndPaginationUserListGetApiResponse,
      ListUsersWithFiltersAndPaginationUserListGetApiArg
    >({
      query: (queryArg) => ({
        params: {
          created_at: queryArg.createdAt,
          deleted_at: queryArg.deletedAt,
          is_active: queryArg.isActive,
          is_creation_date_before: queryArg.isCreationDateBefore,
          is_deletion_date_before: queryArg.isDeletionDateBefore,
          pager_offset: queryArg.pagerOffset,
          pager_size: queryArg.pagerSize,
          role: queryArg.role,
        },
        url: `/user/list`,
      }),
    }),
    loginAuthSignInPost: build.mutation<
      LoginAuthSignInPostApiResponse,
      LoginAuthSignInPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.bodyLoginAuthSignInPost,
        method: 'POST',
        url: `/auth/sign_in`,
      }),
    }),
    logoutAuthLogoutPost: build.mutation<
      LogoutAuthLogoutPostApiResponse,
      LogoutAuthLogoutPostApiArg
    >({
      query: () => ({ method: 'POST', url: `/auth/logout` }),
    }),
    makeNewPaymentPaymentMakePaymentPost: build.mutation<
      MakeNewPaymentPaymentMakePaymentPostApiResponse,
      MakeNewPaymentPaymentMakePaymentPostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { booking_id: queryArg.bookingId },
        url: `/payment/make_payment`,
      }),
    }),
    meUserMeGet: build.query<MeUserMeGetApiResponse, MeUserMeGetApiArg>({
      query: () => ({ url: `/user/me` }),
    }),
    refreshTokenAuthRefreshTokenPost: build.mutation<
      RefreshTokenAuthRefreshTokenPostApiResponse,
      RefreshTokenAuthRefreshTokenPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.refreshToken,
        method: 'POST',
        url: `/auth/refresh_token`,
      }),
    }),
    setHouseAvailableForBookingHouseActivatePost: build.mutation<
      SetHouseAvailableForBookingHouseActivatePostApiResponse,
      SetHouseAvailableForBookingHouseActivatePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/house/activate`,
      }),
    }),
    setHouseStatusByIdHouseSetStatusPost: build.mutation<
      SetHouseStatusByIdHouseSetStatusPostApiResponse,
      SetHouseStatusByIdHouseSetStatusPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.houseStatusCreate,
        method: 'POST',
        params: { house_id: queryArg.houseId },
        url: `/house/set_status`,
      }),
    }),
    setHouseUnavailableForBookingHouseDeactivatePost: build.mutation<
      SetHouseUnavailableForBookingHouseDeactivatePostApiResponse,
      SetHouseUnavailableForBookingHouseDeactivatePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/house/deactivate`,
      }),
    }),
    setPasswordUserSetPasswordPut: build.mutation<
      SetPasswordUserSetPasswordPutApiResponse,
      SetPasswordUserSetPasswordPutApiArg
    >({
      query: (queryArg) => ({
        method: 'PUT',
        params: {
          password: queryArg.password,
          password_confirm: queryArg.passwordConfirm,
        },
        url: `/user/set_password`,
      }),
    }),
    setTariffAvailableForUsingTariffActivatePost: build.mutation<
      SetTariffAvailableForUsingTariffActivatePostApiResponse,
      SetTariffAvailableForUsingTariffActivatePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/tariff/activate`,
      }),
    }),
    setTariffUnavailableForUsingTariffDeactivatePost: build.mutation<
      SetTariffUnavailableForUsingTariffDeactivatePostApiResponse,
      SetTariffUnavailableForUsingTariffDeactivatePostApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        params: { id: queryArg.id },
        url: `/tariff/deactivate`,
      }),
    }),
    updateBookingBookingUpdatePost: build.mutation<
      UpdateBookingBookingUpdatePostApiResponse,
      UpdateBookingBookingUpdatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.bookingUpdateFromApi,
        method: 'POST',
        url: `/booking/update`,
      }),
    }),
    updateHouseHouseUpdatePost: build.mutation<
      UpdateHouseHouseUpdatePostApiResponse,
      UpdateHouseHouseUpdatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.houseUpdate,
        method: 'POST',
        url: `/house/update`,
      }),
    }),
    updateTariffTariffUpdatePost: build.mutation<
      UpdateTariffTariffUpdatePostApiResponse,
      UpdateTariffTariffUpdatePostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.tariffUpdate,
        method: 'POST',
        url: `/tariff/update`,
      }),
    }),
    uploadDocumentImagesImagesUploadDocsImagesPost: build.mutation<
      UploadDocumentImagesImagesUploadDocsImagesPostApiResponse,
      UploadDocumentImagesImagesUploadDocsImagesPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.bodyUploadDocumentImagesImagesUploadDocsImagesPost,
        method: 'POST',
        url: `/images/upload_docs_images`,
      }),
    }),
    uploadImagesImagesUploadImagesPost: build.mutation<
      UploadImagesImagesUploadImagesPostApiResponse,
      UploadImagesImagesUploadImagesPostApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.bodyUploadImagesImagesUploadImagesPost,
        method: 'POST',
        url: `/images/upload_images`,
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };
export type LoginAuthSignInPostApiResponse =
  /** status 200 Successful Response */ JwtTokenResponse;
export interface LoginAuthSignInPostApiArg {
  bodyLoginAuthSignInPost: BodyLoginAuthSignInPost;
}
export type RefreshTokenAuthRefreshTokenPostApiResponse =
  /** status 200 Successful Response */ JwtTokenResponse;
export interface RefreshTokenAuthRefreshTokenPostApiArg {
  refreshToken: RefreshToken;
}
export type LogoutAuthLogoutPostApiResponse =
  /** status 200 Successful Response */ JwtTokenDisabledResponse;
export type LogoutAuthLogoutPostApiArg = void;
export type SetPasswordUserSetPasswordPutApiResponse =
  /** status 200 Successful Response */ UserResponseEmbedded;
export interface SetPasswordUserSetPasswordPutApiArg {
  password: string;
  passwordConfirm: string;
}
export type ActivateUserUserVerifyPostApiResponse =
  /** status 200 Successful Response */ UserResponseEmbedded;
export interface ActivateUserUserVerifyPostApiArg {
  userVerify: UserVerify;
}
export type GetUsersUserAllGetApiResponse =
  /** status 200 Successful Response */ UserListResponse;
export type GetUsersUserAllGetApiArg = void;
export type ListUsersWithFiltersAndPaginationUserListGetApiResponse =
  /** status 200 Successful Response */ UserListHumanResponse;
export interface ListUsersWithFiltersAndPaginationUserListGetApiArg {
  isActive?: boolean;
  createdAt?: string;
  deletedAt?: string;
  isCreationDateBefore?: boolean;
  isDeletionDateBefore?: boolean;
  role?: string;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetApiResponse =
  /** status 200 Successful Response */ UserAwaitingConfirmationAdminViewListResponse;
export interface ListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetApiArg {
  pagerOffset?: number;
  pagerSize?: number;
}
export type CreateWebUserUserCreatePostApiResponse =
  /** status 201 Successful Response */ UserResponseEmbedded;
export interface CreateWebUserUserCreatePostApiArg {
  userCreateWeb: UserCreateWeb;
}
export type AddUserPersonalDataUserAddPersonalDataPostApiResponse =
  /** status 201 Successful Response */ UserResponseEmbeddedWithName;
export interface AddUserPersonalDataUserAddPersonalDataPostApiArg {
  userPersonalDataCreate: UserPersonalDataCreate;
}
export type GetUserByEmailUserUserEmailGetApiResponse =
  /** status 200 Successful Response */ UserResponse;
export interface GetUserByEmailUserUserEmailGetApiArg {
  email: string;
}
export type GetUserVerificationInfoByIdUserUserIdUserIdGetApiResponse =
  /** status 200 Successful Response */ UserAwaitingConfirmationAdminViewResponse;
export interface GetUserVerificationInfoByIdUserUserIdUserIdGetApiArg {
  userId: number;
}
export type MeUserMeGetApiResponse =
  /** status 200 Successful Response */ UserResponse;
export type MeUserMeGetApiArg = void;
export type CleanupUserUserDeleteUserIdDeleteApiResponse =
  /** status 200 Successful Response */ CommonMessageResponse;
export interface CleanupUserUserDeleteUserIdDeleteApiArg {
  userId: number;
}
export type CreateNewOperationRemOpsCreatePostApiResponse =
  /** status 200 Successful Response */ RemoteOperationResponse;
export interface CreateNewOperationRemOpsCreatePostApiArg {
  remoteOperationCreateFromApi: RemoteOperationCreateFromApi;
}
export type GetPendingOperationsForUserRemOpsPendingGetApiResponse =
  /** status 200 Successful Response */ RemoteOperationListResponse;
export type GetPendingOperationsForUserRemOpsPendingGetApiArg = void;
export type GetOperationStatusForHouseByUserRemOpsStatusGetApiResponse =
  /** status 200 Successful Response */ RemoteOperationStatusResponse;
export interface GetOperationStatusForHouseByUserRemOpsStatusGetApiArg {
  id: number;
}
export type ListRemoteOperationsWithFiltersAndPaginationRemOpsListGetApiResponse =
  /** status 200 Successful Response */ RemoteOperationListHumanResponse;
export interface ListRemoteOperationsWithFiltersAndPaginationRemOpsListGetApiArg {
  userId?: number;
  status?: RemoteOperationStatusEnum;
  houseUid?: string;
  bookingId?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetApiResponse =
  /** status 200 Successful Response */ RemoteOperationForUserAccountRepresentationListHumanResponse;
export interface ListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetApiArg {
  houseId: number;
  bookingId: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type GetRolesRoleAllGetApiResponse =
  /** status 200 Successful Response */ RoleListResponse;
export type GetRolesRoleAllGetApiArg = void;
export type GetRoleByNameRoleRoleNameGetApiResponse =
  /** status 200 Successful Response */ RoleResponse;
export interface GetRoleByNameRoleRoleNameGetApiArg {
  name: string;
}
export type CreateNewRoleRoleCreatePostApiResponse =
  /** status 200 Successful Response */ RoleResponse;
export interface CreateNewRoleRoleCreatePostApiArg {
  roleCreate: RoleCreate;
}
export type GetCurrentUserRolesRoleMyRolesGetApiResponse =
  /** status 200 Successful Response */ RoleListResponse;
export type GetCurrentUserRolesRoleMyRolesGetApiArg = void;
export type GetAllUserRoleUserRoleAllGetApiResponse =
  /** status 200 Successful Response */ UserRoleListResponse;
export type GetAllUserRoleUserRoleAllGetApiArg = void;
export type CreateNewUserRoleBindingUserRoleAssignPostApiResponse =
  /** status 200 Successful Response */ UserRoleResponse | string;
export interface CreateNewUserRoleBindingUserRoleAssignPostApiArg {
  userRoleCreate: UserRoleCreate;
}
export type CreateNewHouseHouseCreatePostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface CreateNewHouseHouseCreatePostApiArg {
  houseCreate: HouseCreate;
}
export type GetHouseByIdHouseGetIdGetApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface GetHouseByIdHouseGetIdGetApiArg {
  id: number;
}
export type GetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetApiResponse =
  /** status 200 Successful Response */ HouseWithExtendedTariffsResponse;
export interface GetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetApiArg {
  id: number;
}
export type ListHousesForAdminWithPaginationHouseAdminViewListGetApiResponse =
  /** status 200 Successful Response */ HouseAdminViewListHumanResponse;
export interface ListHousesForAdminWithPaginationHouseAdminViewListGetApiArg {
  id?: number;
  regionId?: number;
  areaId?: number;
  blockId?: number;
  available?: boolean;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListHousesWithFiltersAndPaginationHouseListGetApiResponse =
  /** status 200 Successful Response */ HouseListHumanResponse;
export interface ListHousesWithFiltersAndPaginationHouseListGetApiArg {
  region?: number;
  area?: number;
  block?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetApiResponse =
  /** status 200 Successful Response */ HouseExtendedTariffsListHumanResponse;
export interface ListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetApiArg {
  region?: number;
  area?: number;
  block?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetApiResponse =
  /** status 200 Successful Response */ HouseListHumanResponse;
export interface ListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetApiArg {
  region?: number;
  area?: number;
  block?: number;
  startDate?: string;
  endDate?: string;
  maxCostPerDay?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type ListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetApiResponse =
  /** status 200 Successful Response */ HouseExtendedTariffsListHumanResponse;
export interface ListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetApiArg {
  region?: number;
  area?: number;
  block?: number;
  startDate?: string;
  endDate?: string;
  maxCostPerDay?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type UpdateHouseHouseUpdatePostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface UpdateHouseHouseUpdatePostApiArg {
  houseUpdate: HouseUpdate;
}
export type DeleteHouseHouseDeletePostApiResponse =
  /** status 200 Successful Response */ CommonMessageResponse;
export interface DeleteHouseHouseDeletePostApiArg {
  id: number;
}
export type SetHouseAvailableForBookingHouseActivatePostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface SetHouseAvailableForBookingHouseActivatePostApiArg {
  id: number;
}
export type SetHouseUnavailableForBookingHouseDeactivatePostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface SetHouseUnavailableForBookingHouseDeactivatePostApiArg {
  id: number;
}
export type BindTariffsToHouseHouseBindTariffsPostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface BindTariffsToHouseHouseBindTariffsPostApiArg {
  houseId: number;
  tariffIds: number[];
}
export type GetTariffsBoundToHouseHouseGetBoundTariffsGetApiResponse =
  /** status 200 Successful Response */ number[];
export interface GetTariffsBoundToHouseHouseGetBoundTariffsGetApiArg {
  houseId: number;
}
export type AddOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface AddOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPostApiArg {
  houseId: number;
  coordinate: Coordinate;
}
export type AddOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPostApiResponse =
  /** status 200 Successful Response */ HouseResponse;
export interface AddOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPostApiArg {
  houseId: number;
  isUpdating?: boolean;
  houseImageUrLlist: HouseImageUrLlist;
}
export type GetHouseStatusByIdHouseGetStatusGetApiResponse =
  /** status 200 Successful Response */ HouseStatusResponse;
export interface GetHouseStatusByIdHouseGetStatusGetApiArg {
  houseId: number;
}
export type SetHouseStatusByIdHouseSetStatusPostApiResponse =
  /** status 200 Successful Response */ HouseStatusResponse;
export interface SetHouseStatusByIdHouseSetStatusPostApiArg {
  houseId: number;
  houseStatusCreate: HouseStatusCreate;
}
export type ListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetApiResponse =
  /** status 200 Successful Response */ Idents;
export type ListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetApiArg =
  void;
export type ListHousesToRepresentOnMapWithFiltersHouseListForMapGetApiResponse =
  /** status 200 Successful Response */ HouseMapViewList;
export interface ListHousesToRepresentOnMapWithFiltersHouseListForMapGetApiArg {
  region?: number;
  area?: number;
  block?: number;
  startDate?: string;
  endDate?: string;
  maxCostPerDay?: number;
  isAvailable?: boolean;
}
export type CreateNewTariffTariffCreatePostApiResponse =
  /** status 200 Successful Response */ TariffAdminViewResponse;
export interface CreateNewTariffTariffCreatePostApiArg {
  tariffCreate: TariffCreate;
}
export type UpdateTariffTariffUpdatePostApiResponse =
  /** status 200 Successful Response */ TariffAdminViewResponse;
export interface UpdateTariffTariffUpdatePostApiArg {
  tariffUpdate: TariffUpdate;
}
export type DeleteTariffTariffDeletePostApiResponse =
  /** status 200 Successful Response */ CommonMessageResponse;
export interface DeleteTariffTariffDeletePostApiArg {
  id: number;
}
export type GetTariffByIdTariffGetIdGetApiResponse =
  /** status 200 Successful Response */ TariffResponse;
export interface GetTariffByIdTariffGetIdGetApiArg {
  id: number;
}
export type ListTariffsWithFiltersAndPaginationTariffListGetApiResponse =
  /** status 200 Successful Response */ TariffAdminViewListHumanResponse;
export interface ListTariffsWithFiltersAndPaginationTariffListGetApiArg {
  tariffClass?: string;
  tariffName?: string;
  costPerDay?: number;
  discountPct?: number;
  description?: string;
  isActive?: boolean;
  pagerOffset?: number;
  pagerSize?: number;
}
export type SetTariffAvailableForUsingTariffActivatePostApiResponse =
  /** status 200 Successful Response */ TariffAdminViewResponse;
export interface SetTariffAvailableForUsingTariffActivatePostApiArg {
  id: number;
}
export type SetTariffUnavailableForUsingTariffDeactivatePostApiResponse =
  /** status 200 Successful Response */ TariffAdminViewResponse;
export interface SetTariffUnavailableForUsingTariffDeactivatePostApiArg {
  id: number;
}
export type CreateNewBookingBookingCreatePostApiResponse =
  /** status 200 Successful Response */ BookingResponse;
export interface CreateNewBookingBookingCreatePostApiArg {
  bookingCreateFromApi: BookingCreateFromApi;
}
export type UpdateBookingBookingUpdatePostApiResponse =
  /** status 200 Successful Response */ BookingResponse;
export interface UpdateBookingBookingUpdatePostApiArg {
  bookingUpdateFromApi: BookingUpdateFromApi;
}
export type CancelBookingByUserBookingCancelByUserPostApiResponse =
  /** status 200 Successful Response */ BookingResponse;
export interface CancelBookingByUserBookingCancelByUserPostApiArg {
  bookingId: number;
}
export type DeleteBookingBookingDeletePostApiResponse =
  /** status 200 Successful Response */ CommonMessageResponse;
export interface DeleteBookingBookingDeletePostApiArg {
  id: number;
}
export type GetBookingByIdBookingGetIdGetApiResponse =
  /** status 200 Successful Response */ BookingResponse;
export interface GetBookingByIdBookingGetIdGetApiArg {
  id: number;
}
export type ListBookingsWithFiltersAndPaginationBookingListGetApiResponse =
  /** status 200 Successful Response */ BookingListHumanResponse;
export interface ListBookingsWithFiltersAndPaginationBookingListGetApiArg {
  userId?: number;
  houseId?: number;
  tariffId?: number;
  status?: BookingStatusEnum;
  startDate?: string;
  endDate?: string;
  durationDays?: number;
  price?: number;
  pagerOffset?: number;
  pagerSize?: number;
}
export type GetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetApiResponse =
  /** status 200 Successful Response */ BookingUnavailableDatesResponse;
export interface GetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetApiArg {
  houseId: number;
}
export type MakeNewPaymentPaymentMakePaymentPostApiResponse =
  /** status 200 Successful Response */ any;
export interface MakeNewPaymentPaymentMakePaymentPostApiArg {
  bookingId: number;
}
export type UploadImagesImagesUploadImagesPostApiResponse =
  /** status 200 Successful Response */ ImageUrlDataList;
export interface UploadImagesImagesUploadImagesPostApiArg {
  bodyUploadImagesImagesUploadImagesPost: BodyUploadImagesImagesUploadImagesPost;
}
export type DeleteImagesImagesDeleteImagesPostApiResponse =
  /** status 200 Successful Response */ CommonMessageResponse;
export interface DeleteImagesImagesDeleteImagesPostApiArg {
  imageUrlDataList: ImageUrlDataList;
}
export type UploadDocumentImagesImagesUploadDocsImagesPostApiResponse =
  /** status 200 Successful Response */ ImageUrlDataList;
export interface UploadDocumentImagesImagesUploadDocsImagesPostApiArg {
  bodyUploadDocumentImagesImagesUploadDocsImagesPost: BodyUploadDocumentImagesImagesUploadDocsImagesPost;
}
export type GetDocumentImagesImagesGetDocsImagesPostApiResponse =
  /** status 200 Successful Response */ ImageUrlDataList;
export interface GetDocumentImagesImagesGetDocsImagesPostApiArg {
  imageKeysList: ImageKeysList;
}
export interface JwtTokenResponse {
  access_token: string;
}
export interface ExceptionMessage {
  status_code: number;
  error_details: string;
  error_code: number;
}
export interface ValidationError {
  loc: (string | number)[];
  msg: string;
  type: string;
}
export interface HttpValidationError {
  detail?: ValidationError[];
}
export interface BodyLoginAuthSignInPost {
  grant_type?: string;
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
}
export interface RefreshToken {
  refresh_token: string;
}
export interface JwtTokenDisabledResponse {
  access_token_disabled: string;
}
export interface UserResponseEmbedded {
  id: number;
  created_at: string;
  email?: string;
  is_active: boolean;
}
export interface UserVerify {
  user_id: number;
}
export interface UserResponse {
  id: number;
  created_at: string;
  email?: string;
  is_active: boolean;
  role_id?: number;
  role?: string;
  firstname?: string;
  surname?: string;
  patronymic?: string;
}
export interface UserListResponse {
  users: UserResponse[];
}
export interface EdgePager {
  offset: number;
  size: number;
  total: number;
}
export interface UserListHumanResponse {
  users: UserResponse[];
  edge: EdgePager;
}
export interface UserAwaitingConfirmationAdminViewResponse {
  id?: number;
  created_at?: string;
  email?: string;
  firstname?: string;
  surname?: string;
  patronymic?: string;
  doc_scan_keys?: string[];
}
export interface UserAwaitingConfirmationAdminViewListResponse {
  users: UserAwaitingConfirmationAdminViewResponse[];
  edge: EdgePager;
}
export interface UserCreateWeb {
  email: string;
  password: string;
}
export interface UserResponseEmbeddedWithName {
  id: number;
  created_at: string;
  email?: string;
  is_active: boolean;
  firstname?: string;
  surname?: string;
  patronymic?: string;
}
export interface UserPersonalDataCreate {
  firstname: string;
  surname: string;
  patronymic?: string;
  doc_scan_keys: string[];
}
export interface CommonMessageResponse {
  message?: string;
}
export interface RemoteOperationResponse {
  id?: number;
  sender_id?: number;
  created_at?: string;
  operation_type?: RemoteOperationTypeEnum;
  status?: RemoteOperationStatusEnum;
  house_uid: string;
  booking_id?: number;
}
export interface RemoteOperationCreateFromApi {
  house_id: number;
  booking_id?: number;
  operation_type: RemoteOperationTypeEnum;
}
export interface RemoteOperation {
  id?: number;
  sender_id?: number;
  created_at?: string;
  operation_type?: RemoteOperationTypeEnum;
  status?: RemoteOperationStatusEnum;
  house_uid: string;
  booking_id?: number;
}
export interface RemoteOperationListResponse {
  remote_operations: RemoteOperation[];
}
export interface RemoteOperationStatusResponse {
  status: RemoteOperationStatusEnum;
}
export interface RemoteOperationListHumanResponse {
  remote_operations: RemoteOperation[];
  edge: EdgePager;
}
export interface RemoteOperationForUserAccountRepresentation {
  operation_type: RemoteOperationTypeEnum;
  status: RemoteOperationStatusEnum;
  created_at: string;
}
export interface RemoteOperationForUserAccountRepresentationListHumanResponse {
  remote_operations: RemoteOperationForUserAccountRepresentation[];
  edge: EdgePager;
}
export interface RoleResponse {
  id?: number;
  name?: string;
  description?: string;
}
export interface RoleListResponse {
  roles: RoleResponse[];
}
export interface RoleCreate {
  name: string;
  description?: string;
}
export interface UserRole {
  user_id?: number;
  role_id?: number;
  role?: string;
}
export interface UserRoleListResponse {
  user_roles: UserRole[];
}
export interface UserRoleResponse {
  user_id?: number;
  role_id?: number;
  role?: string;
}
export interface UserRoleCreate {
  user_id: number;
  role_id: number;
}
export interface HouseResponse {
  latitude?: number;
  longtitude?: number;
  id?: number;
  region_id?: number;
  area_id?: number;
  block_id?: number;
  room?: number;
  available?: boolean;
  applicable_tariff_ids?: number[];
  services?: string[];
  image_links?: string[];
  status?: HouseStatusEnum;
}
export interface HouseCreate {
  latitude?: number;
  longtitude?: number;
  region_id: number;
  area_id: number;
  block_id: number;
  room: number;
  services?: string[];
  applicable_tariff_ids?: number[];
  image_links?: string[];
}
export interface Tariff {
  id?: number;
  tariff_name?: string;
  tariff_class?: string;
  cost_per_day?: number;
  discount_pct?: number;
  description?: string;
  is_active?: boolean;
  is_deleted?: boolean;
  deleted_at?: string;
  updated_at?: string;
  created_at?: string;
}
export interface HouseWithExtendedTariffsResponse {
  latitude?: number;
  longtitude?: number;
  id?: number;
  region_id?: number;
  area_id?: number;
  block_id?: number;
  room?: number;
  available?: boolean;
  applicable_tariff_ids?: number[];
  services?: string[];
  image_links?: string[];
  status?: HouseStatusEnum;
  tariffs: Tariff[];
}
export interface HouseAdminViewResponse {
  latitude?: number;
  longtitude?: number;
  id?: number;
  region_id?: number;
  area_id?: number;
  block_id?: number;
  room?: number;
  available?: boolean;
  applicable_tariff_ids?: number[];
  services?: string[];
  image_links?: string[];
  status?: HouseStatusEnum;
  updated_at?: string;
  created_at?: string;
}
export interface HouseAdminViewListHumanResponse {
  houses: HouseAdminViewResponse[];
  edge: EdgePager;
}
export interface HouseListHumanResponse {
  houses: HouseResponse[];
  edge: EdgePager;
}
export interface HouseWithExtendedTariffs {
  latitude?: number;
  longtitude?: number;
  id?: number;
  region_id?: number;
  area_id?: number;
  block_id?: number;
  room?: number;
  available?: boolean;
  applicable_tariff_ids?: number[];
  services?: string[];
  image_links?: string[];
  status?: HouseStatusEnum;
  tariffs: Tariff[];
}
export interface HouseExtendedTariffsListHumanResponse {
  houses: HouseWithExtendedTariffs[];
  edge: EdgePager;
}
export interface HouseUpdate {
  latitude?: number;
  longtitude?: number;
  id: number;
  region_id?: number;
  area_id?: number;
  block_id?: number;
  room?: number;
  services?: string[];
  applicable_tariff_ids?: number[];
  image_links?: string[];
}
export interface Coordinate {
  latitude?: number;
  longtitude?: number;
}
export interface HouseImageUrl {
  url: string;
}
export interface HouseImageUrLlist {
  image_links: HouseImageUrl[];
}
export interface HouseStatusResponse {
  id?: number;
  status?: HouseStatusEnum;
}
export interface HouseStatusCreate {
  status?: HouseStatusEnum;
}
export interface Idents {
  regions: number[];
  areas: number[];
  blocks: number[];
}
export interface HouseMapViewInternal {
  latitude?: number;
  longtitude?: number;
  id: number;
}
export interface HouseMapViewList {
  houses: HouseMapViewInternal[];
}
export interface TariffAdminViewResponse {
  id?: number;
  tariff_name?: string;
  tariff_class?: string;
  cost_per_day?: number;
  discount_pct?: number;
  description?: string;
  is_active?: boolean;
  updated_at?: string;
  created_at?: string;
}
export interface TariffCreate {
  tariff_name: string;
  tariff_class: string;
  cost_per_day: number;
  discount_pct?: number;
  description?: string;
}
export interface TariffUpdate {
  id: number;
  tariff_name?: string;
  tariff_class?: string;
  cost_per_day?: number;
  discount_pct?: number;
  description?: string;
}
export interface TariffResponse {
  id?: number;
  tariff_name?: string;
  tariff_class?: string;
  cost_per_day?: number;
  discount_pct?: number;
  description?: string;
  is_active?: boolean;
}
export interface TariffAdminViewListHumanResponse {
  tariffs: TariffAdminViewResponse[];
  edge: EdgePager;
}
export interface BookingResponse {
  id?: number;
  user_id?: number;
  house_id?: number;
  tariff_id?: number;
  status?: BookingStatusEnum;
  start_date?: string;
  end_date?: string;
  duration_days?: number;
  price?: number;
}
export interface BookingCreateFromApi {
  user_id: number;
  house_id: number;
  tariff_id: number;
  start_date: string;
  end_date: string;
}
export interface BookingUpdateFromApi {
  id: number;
  user_id?: number;
  house_id?: number;
  tariff_id?: number;
  start_date?: string;
  end_date?: string;
}
export interface Booking {
  id?: number;
  user_id?: number;
  hotel_id?: number;
  tariff_id?: number;
  status?: BookingStatusEnum;
  start_date?: string;
  end_date?: string;
  duration_days?: number;
  price?: number;
}
export interface BookingListHumanResponse {
  requests: Booking[];
  edge: EdgePager;
}
export interface BookingUnavailableDatesResponse {
  unavailable_dates: string[][];
}
export interface ImageUrlData {
  url: string;
}
export interface ImageUrlDataList {
  images: ImageUrlData[];
}
export interface BodyUploadImagesImagesUploadImagesPost {
  images: Blob[];
}
export interface BodyUploadDocumentImagesImagesUploadDocsImagesPost {
  images: Blob[];
}
export interface ImageKeysList {
  keys: string[];
}
export enum RemoteOperationTypeEnum {
  SetHouseInAvailableMode = 'SET_HOUSE_IN_AVAILABLE_MODE',
  SetHouseInBookedMode = 'SET_HOUSE_IN_BOOKED_MODE',
  OpenHouse = 'OPEN_HOUSE',
  CloseHouse = 'CLOSE_HOUSE',
  PauseHouse = 'PAUSE_HOUSE',
  SetHouseInServiceMode = 'SET_HOUSE_IN_SERVICE_MODE',
}
export enum RemoteOperationStatusEnum {
  $0 = 0,
  $1 = 1,
  $2 = 2,
}
export enum HouseStatusEnum {
  Open = 'OPEN',
  Pause = 'PAUSE',
  Close = 'CLOSE',
  InService = 'IN_SERVICE',
  InAction = 'IN_ACTION',
  Unknown = 'UNKNOWN',
  Error = 'ERROR',
}
export enum BookingStatusEnum {
  Pending = 'PENDING',
  Paid = 'PAID',
  CheckedIn = 'CHECKED_IN',
  Completed = 'COMPLETED',
  CanceledByUser = 'CANCELED_BY_USER',
  CanceledByPaymentTimeout = 'CANCELED_BY_PAYMENT_TIMEOUT',
  Refunded = 'REFUNDED',
}
export const {
  useActivateUserUserVerifyPostMutation,
  useAddOrUpdateCoordinatesToHouseHouseAddOrUpdateCoordinatesPostMutation,
  useAddOrUpdateImageLinksToHouseHouseAddOrUpdateImageLinksPostMutation,
  useAddUserPersonalDataUserAddPersonalDataPostMutation,
  useBindTariffsToHouseHouseBindTariffsPostMutation,
  useCancelBookingByUserBookingCancelByUserPostMutation,
  useCleanupUserUserDeleteUserIdDeleteMutation,
  useCreateNewBookingBookingCreatePostMutation,
  useCreateNewHouseHouseCreatePostMutation,
  useCreateNewOperationRemOpsCreatePostMutation,
  useCreateNewRoleRoleCreatePostMutation,
  useCreateNewTariffTariffCreatePostMutation,
  useCreateNewUserRoleBindingUserRoleAssignPostMutation,
  useCreateWebUserUserCreatePostMutation,
  useDeleteBookingBookingDeletePostMutation,
  useDeleteHouseHouseDeletePostMutation,
  useDeleteImagesImagesDeleteImagesPostMutation,
  useDeleteTariffTariffDeletePostMutation,
  useGetAllUserRoleUserRoleAllGetQuery,
  useGetBookingByIdBookingGetIdGetQuery,
  useGetCurrentUserRolesRoleMyRolesGetQuery,
  useGetDocumentImagesImagesGetDocsImagesPostMutation,
  useGetHouseByIdHouseGetIdGetQuery,
  useGetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetQuery,
  useGetHouseStatusByIdHouseGetStatusGetQuery,
  useGetOperationStatusForHouseByUserRemOpsStatusGetQuery,
  useGetPendingOperationsForUserRemOpsPendingGetQuery,
  useGetRoleByNameRoleRoleNameGetQuery,
  useGetRolesRoleAllGetQuery,
  useGetTariffByIdTariffGetIdGetQuery,
  useGetTariffsBoundToHouseHouseGetBoundTariffsGetQuery,
  useGetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetQuery,
  useGetUserByEmailUserUserEmailGetQuery,
  useGetUserVerificationInfoByIdUserUserIdUserIdGetQuery,
  useGetUsersUserAllGetQuery,
  useLazyGetAllUserRoleUserRoleAllGetQuery,
  useLazyGetBookingByIdBookingGetIdGetQuery,
  useLazyGetCurrentUserRolesRoleMyRolesGetQuery,
  useLazyGetHouseByIdHouseGetIdGetQuery,
  useLazyGetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetQuery,
  useLazyGetHouseStatusByIdHouseGetStatusGetQuery,
  useLazyGetOperationStatusForHouseByUserRemOpsStatusGetQuery,
  useLazyGetPendingOperationsForUserRemOpsPendingGetQuery,
  useLazyGetRoleByNameRoleRoleNameGetQuery,
  useLazyGetRolesRoleAllGetQuery,
  useLazyGetTariffByIdTariffGetIdGetQuery,
  useLazyGetTariffsBoundToHouseHouseGetBoundTariffsGetQuery,
  useLazyGetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetQuery,
  useLazyGetUserByEmailUserUserEmailGetQuery,
  useLazyGetUserVerificationInfoByIdUserUserIdUserIdGetQuery,
  useLazyGetUsersUserAllGetQuery,
  useLazyListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetQuery,
  useLazyListBookingsWithFiltersAndPaginationBookingListGetQuery,
  useLazyListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetQuery,
  useLazyListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetQuery,
  useLazyListHousesForAdminWithPaginationHouseAdminViewListGetQuery,
  useLazyListHousesToRepresentOnMapWithFiltersHouseListForMapGetQuery,
  useLazyListHousesWithFiltersAndPaginationHouseListGetQuery,
  useLazyListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetQuery,
  useLazyListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetQuery,
  useLazyListRemoteOperationsWithFiltersAndPaginationRemOpsListGetQuery,
  useLazyListTariffsWithFiltersAndPaginationTariffListGetQuery,
  useLazyListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetQuery,
  useLazyListUsersWithFiltersAndPaginationUserListGetQuery,
  useLazyMeUserMeGetQuery,
  useListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetQuery,
  useListBookingsWithFiltersAndPaginationBookingListGetQuery,
  useListHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGetQuery,
  useListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetQuery,
  useListHousesForAdminWithPaginationHouseAdminViewListGetQuery,
  useListHousesToRepresentOnMapWithFiltersHouseListForMapGetQuery,
  useListHousesWithFiltersAndPaginationHouseListGetQuery,
  useListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetQuery,
  useListRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGetQuery,
  useListRemoteOperationsWithFiltersAndPaginationRemOpsListGetQuery,
  useListTariffsWithFiltersAndPaginationTariffListGetQuery,
  useListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetQuery,
  useListUsersWithFiltersAndPaginationUserListGetQuery,
  useLoginAuthSignInPostMutation,
  useLogoutAuthLogoutPostMutation,
  useMakeNewPaymentPaymentMakePaymentPostMutation,
  useMeUserMeGetQuery,
  useRefreshTokenAuthRefreshTokenPostMutation,
  useSetHouseAvailableForBookingHouseActivatePostMutation,
  useSetHouseStatusByIdHouseSetStatusPostMutation,
  useSetHouseUnavailableForBookingHouseDeactivatePostMutation,
  useSetPasswordUserSetPasswordPutMutation,
  useSetTariffAvailableForUsingTariffActivatePostMutation,
  useSetTariffUnavailableForUsingTariffDeactivatePostMutation,
  useUpdateBookingBookingUpdatePostMutation,
  useUpdateHouseHouseUpdatePostMutation,
  useUpdateTariffTariffUpdatePostMutation,
  useUploadDocumentImagesImagesUploadDocsImagesPostMutation,
  useUploadImagesImagesUploadImagesPostMutation,
} = injectedRtkApi;
