import React, { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import VirtualList from 'react-virtual-drag-list';
import cx from 'classnames';

import {
  ExportIcon,
  Loader,
  Maybe,
  TrashDenseIcon,
  Typography,
} from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface UploadCallbackItem {
  key: string;
  url: string;
}
interface UploadImageProps {
  initValue: string[];
  onChange(val: string[]): void;
  uploadCallback(files: File[]): Promise<Maybe<UploadCallbackItem[]>>;
  maxCount?: number;
  resolution?: string;
  draggable?: boolean;
  removed?: boolean;
}
interface UploadItem {
  key: string;
  value: string;
  url: string;
}
export const UploadImage: FC<UploadImageProps> = ({
  draggable = true,
  initValue,
  maxCount = 99,
  onChange,
  removed = true,
  resolution,
  uploadCallback,
}) => {
  const [currentItems, setCurrentItems] = useState<UploadItem[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const uploaderRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (Array.isArray(initValue) && currentItems.length === 0) {
      setCurrentItems(
        initValue.map((el) => ({
          key: crypto.randomUUID(),
          url: el,
          value: el,
        })),
      );
    }
  }, [initValue]);

  const openUpload = () => {
    setIsUploading(true);
    if (uploaderRef.current) {
      uploaderRef.current.click();
    }
    window.addEventListener(
      'focus',
      () => {
        setIsUploading(false);
      },
      { once: true },
    );
  };

  const removeImage = (key: string) => {
    const updatedItems = currentItems.filter((item) => item.key !== key);

    setCurrentItems(updatedItems);
    handleChange(updatedItems);
  };

  const loadImage = async (files: FileList) => {
    let filesUpload: File[] = [];

    const maxUploadCount = Math.min(
      maxCount - currentItems.length,
      files.length,
    );

    filesUpload = [...files].slice(0, maxUploadCount);

    // Simulating an image upload and adding the images to currentItems
    const uploadedImages = await uploadImageHandler(filesUpload);

    if (uploadedImages) {
      setCurrentItems((prev) => {
        const newItems = [
          ...prev,
          ...uploadedImages.map((el) => ({
            key: crypto.randomUUID(),
            url: el.url,
            value: el.key,
          })),
        ];

        handleChange(newItems);

        return newItems;
      });
    }
  };

  // Mock upload function
  const uploadImageHandler = async (files: File[]) => {
    setIsLoading(true);
    const links = await uploadCallback(files);

    setIsLoading(false);

    return links;
  };
  const onDrop = (event: { list: UploadItem[] }) => {
    setCurrentItems(event.list);
    handleChange(event.list);
  };

  const handleChange = (updatedItems: UploadItem[]) => {
    onChange(updatedItems.map((el) => el.value));
  };

  return (
    <div className={styles.uploadImages}>
      <div className={styles.uploadImages__list}>
        <VirtualList
          wrapClass={cx(styles.uploadImages__list, 'upload-img-list')}
          dataKey="key"
          dataSource={currentItems}
          handle=".drag"
          onDrop={onDrop}>
          {(record, index, dataKey) => (
            <li
              key={dataKey}
              className={cx(
                styles.uploadImages__item,
                draggable && styles.uploadImages__item_drag,
              )}>
              <img
                className={cx(styles.uploadImages__itemImg, 'drag')}
                src={record.url}
                alt="uploaded"
              />
              {removed && (
                <button
                  className={styles.uploadImages__itemDelete}
                  onClick={() => removeImage(record.key)}>
                  <TrashDenseIcon
                    width={14}
                    height={14}
                    color="var(--neutrals-1)"
                    className={styles.uploadImages__itemDeleteIcon}
                  />
                </button>
              )}
            </li>
          )}
        </VirtualList>
        {Array.isArray(currentItems) &&
          currentItems.length < maxCount &&
          document.querySelector('.upload-img-list') &&
          createPortal(
            <div
              className={cx(
                styles.uploadImages__item,
                styles.uploadImages__item_upload,
              )}
              onClick={openUpload}>
              <div className={styles.uploadImages__itemUpload}>
                {isUploading || isLoading ? (
                  <Loader />
                ) : (
                  <ExportIcon className={styles.uploadImages__itemUploadIcon} />
                )}
                {resolution && (
                  <Typography
                    variant="body-14"
                    color="on-surface-secondary-2"
                    className={styles.uploadImages__itemUploadSize}>
                    {resolution}
                  </Typography>
                )}
              </div>
              <input
                type="file"
                ref={uploaderRef}
                style={{ display: 'none' }}
                accept="image/*"
                multiple={maxCount !== 1}
                onChange={(e) => {
                  if (e.target.files) {
                    loadImage(e.target.files);
                  }
                }}
              />
            </div>,
            document.querySelector('.upload-img-list')!,
          )}
      </div>
    </div>
  );
};
