import React, { FC, useEffect, useMemo, useState } from 'react';
import { DateFilterOptions } from 'react-datepicker/dist/date_utils';
import moment from 'moment';

import { DateInput } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  Maybe,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk, Tariff } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';
import { getIsoDate } from '@shared/libs/utils/format';

import styles from './styles.module.scss';

interface BookingHouseModalProps {
  house_id: number;
  tariff: Tariff;
}
declare global {
  interface ModalProps {
    bookingHouse: BookingHouseModalProps;
  }
}
export const BookingHouseModal: FC<WithCloseModal<BookingHouseModalProps>> = ({
  house_id,
  onClose,
  tariff,
}): JSX.Element => {
  const { data: disabledDatesData } =
    sdk.useGetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetQuery(
      { houseId: house_id },
    );

  const excludeDateIntervals = useMemo<
    DateFilterOptions['excludeDateIntervals']
  >(() => {
    if (!disabledDatesData) {
      return [];
    }
    const result: DateFilterOptions['excludeDateIntervals'] = [];

    for (const el of disabledDatesData.unavailable_dates) {
      if (el[0] && el[1]) {
        result.push({
          end: new Date(el[1]),
          start: moment(new Date(el[0])).subtract(1, 'days').toDate(),
        });
      }
    }

    return result;
  }, [disabledDatesData]);

  const { data: user } = sdk.useMeUserMeGetQuery();
  const [bookingHouse, { data, error: apiError }] =
    sdk.useCreateNewBookingBookingCreatePostMutation();
  const [error, setError] = useState<Maybe<string>>(null);
  const [dates, setDates] = useState<[Maybe<Date>, Maybe<Date>]>([null, null]);

  const handleSubmit = () => {
    setError(null);
    if (!user) {
      return;
    }
    bookingHouse({
      bookingCreateFromApi: {
        end_date: getIsoDate(dates[1]!),
        house_id,
        start_date: getIsoDate(dates[0]!),
        tariff_id: tariff.id!,
        user_id: user.id,
      },
    });
  };

  useEffect(() => {
    if (data) {
      onClose();
    }
    if (data) {
      toaster.success({ title: 'Дом успешно забронирован' });
    }
    if (apiError) {
      setError(
        isFetchBaseQueryError(apiError)
          ? apiError.data.error_details
          : 'Ошибка бронирования дома',
      );
    }
  }, [data, apiError, setError]);

  return (
    <Dialog
      title={`Выберите даты бронирования дома ${house_id} по тарифу ${tariff.tariff_name}`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              onClick={handleSubmit}
              disabled={!dates[0] || !dates[1]}>
              Забронировать
            </Button>
          }
        />
      }>
      <div className={styles.bookingHouse__dates}>
        <DateInput
          value={dates}
          onChange={setDates}
          excludeDateIntervals={excludeDateIntervals}
          error={error}
          placeholder="Даты бронирования"
        />
      </div>
    </Dialog>
  );
};
