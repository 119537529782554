import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Maybe, toaster, Typography } from '@house-booking/ui-kit';
import {
  RemoteOperationStatusEnum,
  RemoteOperationTypeEnum,
  sdk,
} from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export const useCreateOperation = (
  house_id: number,
  operation_type: RemoteOperationTypeEnum,
) => {
  const dispatch = useDispatch();
  const [createOperation, { data: dataCreate, error: errorCreate }] =
    sdk.useCreateNewOperationRemOpsCreatePostMutation();
  const [operationId, setOperationId] = useState<Maybe<number>>(null);
  const { data: statusData } =
    sdk.useGetOperationStatusForHouseByUserRemOpsStatusGetQuery(
      {
        id: operationId!,
      },
      { pollingInterval: 1500, skip: !operationId },
    );

  const handleCreate = () => {
    createOperation({
      remoteOperationCreateFromApi: {
        house_id,
        operation_type,
      },
    });
  };

  useEffect(() => {
    if (dataCreate) {
      toaster.warning({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Ожидайте её выполнения
          </Typography>
        ),
        title: 'Операция успешно создана',
      });
      setOperationId(dataCreate.id!);
    }
    if (errorCreate) {
      toaster.error({
        title: isFetchBaseQueryError(errorCreate)
          ? errorCreate.data.error_details
          : 'Ошибка создания операции',
      });
    }
  }, [dataCreate, errorCreate]);

  useEffect(() => {
    if (statusData?.status === RemoteOperationStatusEnum.$1) {
      toaster.success({ title: 'Операция успешно выполнена' });
    }
    if (statusData?.status === RemoteOperationStatusEnum.$2) {
      toaster.error({ title: 'Операция завершилась с ошибкой' });
    }
    if (
      statusData?.status === RemoteOperationStatusEnum.$2 ||
      statusData?.status === RemoteOperationStatusEnum.$1
    ) {
      // @ts-ignore
      dispatch(sdk.util.invalidateTags(['Operation', 'House']));
      setOperationId(null);
    }
  }, [statusData]);

  return handleCreate;
};
