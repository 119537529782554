import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { AdminHouseCreate } from '@features/Admin';

export const AdminHouseCreateView: FC = () => {
  const { navigate } = useNavigate();

  const handleCancel = () => {
    navigate(RouterName.AdminHouses);
  };

  return (
    <div>
      <Head title="Создание дома" onBack={handleCancel} />
      <AdminHouseCreate onCancel={handleCancel} />
    </div>
  );
};
