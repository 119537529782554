import moment from 'moment/moment';

import { Tariff } from '@shared/api';

export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru').format(fixed ? +number.toFixed(0) : number);
export const getCoords = <
  T extends { latitude?: number; longtitude?: number; id?: number },
>(
  item: T,
): [number, number] => [item.latitude!, item.longtitude!];
export const getMinPrice = (items: Tariff[]): number => {
  let minPrice = items[0]?.cost_per_day || 0;

  for (const el of items) {
    if (el.cost_per_day && el.cost_per_day < minPrice) {
      minPrice = el.cost_per_day;
    }
  }

  return minPrice;
};

export const getIsoDate = (date: Date): string =>
  moment(date).format('YYYY-MM-DD');

export const pagination = (page: number, pageSize: number) => ({
  pagerOffset: (page - 1) * pageSize,
  pagerSize: pageSize,
});
