import { useEffect } from 'react';

import { toaster } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export const useCancelBooking = () => {
  const [cancelBooking, { data, error }] =
    sdk.useCancelBookingByUserBookingCancelByUserPostMutation();

  const handleCancel = (id: number) => {
    cancelBooking({
      bookingId: id,
    });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Бронирование успешно отменено',
      });
    }
    if (error) {
      toaster.error({
        title: isFetchBaseQueryError(error)
          ? error.data.error_details
          : 'Ошибка отмены бронирования',
      });
    }
  }, [data, error]);

  return handleCancel;
};
