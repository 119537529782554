import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { useIsVerify } from '@entities/Auth/libs/hooks';
import { Divider, Spacer, TabItem, Tabs } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export enum UserTabsEnum {
  Profile,
  History,
}
export interface UserTabsProps {
  tab: UserTabsEnum;
}
export const UserTabs: FC<UserTabsProps> = ({ tab }) => {
  const { navigate } = useNavigate();
  const isVerify = useIsVerify();

  return (
    <div className={styles.userTabs}>
      <Spacer size={12} />
      <Container>
        <Tabs className={styles.userTabs__tabs} initValue={tab}>
          <TabItem
            label="Профиль"
            value={UserTabsEnum.Profile}
            onClick={() => navigate(RouterName.Profile)}
          />
          <TabItem
            label="История"
            value={UserTabsEnum.History}
            disabled={!isVerify}
            onClick={() => navigate(RouterName.History)}
          />
        </Tabs>
      </Container>
      <Divider />
    </div>
  );
};
