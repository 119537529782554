import { FC } from 'react';

import { Head } from '@/shared';
import { AdminUsersConfirmationList } from '@widgets/Admin';

export const AdminUsersConfirmationView: FC = () => (
  <div>
    <Head title="Список необработанных пользователей" />
    <AdminUsersConfirmationList />
  </div>
);
