import React from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { Redirect } from 'react-justanother-router';

import { Role, RouterName } from './types';

const getUserRole = (): Role => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {
    return Role.USER;
  }

  return Role.GHOST;
};

export const routeMiddleware = (
  roleAccess: Role | undefined,
): JSX.Element | void => {
  const userRole = getUserRole();

  if (roleAccess === userRole) {
    return;
  }
  switch (roleAccess) {
    case Role.USER: {
      return <Redirect to={RouterName.Login} />;
    }
    case Role.GHOST: {
      return <Redirect to={RouterName.Filter} />;
    }
  }
};
