import { Route } from 'react-justanother-router';

import { Role, RouterName } from '@app/router';
import {
  AdminHouseCreateView,
  AdminHouseEditView,
  AdminHousesView,
  AdminTariffCreateView,
  AdminTariffEditView,
  AdminTariffsView,
  AdminUserConfirmationView,
  AdminUsersConfirmationView,
  AdminView,
} from '@pages/Admin';
import {
  AdminEditorLayout,
  AdminLayout,
  AdminUserConfirmLayout,
} from '@widgets/Layout';

export const adminRoutes: Route = {
  children: [
    {
      children: [
        {
          component: AdminHouseEditView,
          name: RouterName.AdminHouseEdit,
          path: '/edit/:id',
          props: {
            accessRole: Role.USER,
            layout: AdminEditorLayout,
          },
        },
        {
          component: AdminHouseCreateView,
          name: RouterName.AdminHouseCreate,
          path: '/create',
          props: {
            accessRole: Role.USER,
            layout: AdminEditorLayout,
          },
        },
      ],
      component: AdminHousesView,
      name: RouterName.AdminHouses,
      path: '/houses',
      props: {
        accessRole: Role.USER,
        layout: AdminEditorLayout,
      },
    },
    {
      children: [
        {
          component: AdminTariffEditView,
          name: RouterName.AdminTariffEdit,
          path: '/edit/:id',
          props: {
            accessRole: Role.USER,
            layout: AdminEditorLayout,
          },
        },
        {
          component: AdminTariffCreateView,
          name: RouterName.AdminTariffCreate,
          path: '/create',
          props: {
            accessRole: Role.USER,
            layout: AdminEditorLayout,
          },
        },
      ],
      component: AdminTariffsView,
      name: RouterName.AdminTariffs,
      path: '/tariffs',
      props: {
        accessRole: Role.USER,
        layout: AdminEditorLayout,
      },
    },
    {
      children: [
        {
          component: AdminUserConfirmationView,
          name: RouterName.AdminConfirmUser,
          path: '/:id',
          props: {
            accessRole: Role.USER,
            layout: AdminUserConfirmLayout,
          },
        },
      ],
      component: AdminUsersConfirmationView,
      name: RouterName.AdminConfirmationUsers,
      path: '/users-confirm',
      props: {
        accessRole: Role.USER,
        layout: AdminUserConfirmLayout,
      },
    },
  ],
  component: AdminView,
  name: RouterName.Admin,
  path: '/admin',
  props: {
    accessRole: Role.USER,
    layout: AdminLayout,
  },
};
