import { FC } from 'react';
import cx from 'classnames';

import { Spacer, Typography } from '@house-booking/ui-kit';
import { HouseResponse } from '@shared/api';

import styles from './styles.module.scss';

interface HouseCharsListProps {
  house: HouseResponse;
  title?: string;
  className?: string;
}
export const HouseCharsList: FC<HouseCharsListProps> = ({
  className,
  house,
  title,
}) => {
  const list = [
    { label: 'Регион', value: String(house.region_id) },
    { label: 'Область', value: String(house.area_id) },
    { label: 'Блок', value: String(house.block_id) },
    { label: 'Комната', value: String(house.room) },
  ];

  return (
    <div className={cx(styles.card, className)}>
      <Typography className={cx(className)} variant="head-32">
        {title}
      </Typography>
      <ul>
        {list.map(({ label, value }, i) => (
          <li key={i} className={styles.card__item}>
            <Typography variant="body-14" color="on-surface-primary-1">
              {label}
            </Typography>
            <Typography variant="body-14" color="on-surface-primary-2">
              {value}
            </Typography>
          </li>
        ))}
      </ul>
      <Spacer size={12} />
      <Typography className={cx(className)} variant="head-20">
        Главные удобства
      </Typography>

      {!!house.services?.length && (
        <ul>
          {house.services.map((service, i) => (
            <Typography
              tag="li"
              key={i}
              variant="body-14"
              color="on-surface-primary-2"
              className={styles.card__item}>
              {service}
            </Typography>
          ))}
        </ul>
      )}
    </div>
  );
};
