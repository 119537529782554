import { FC } from 'react';

import { FieldLabel, UploadImage } from '@/shared';
import { verifySchema } from '@entities/Auth';
import {
  Button,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { UserPersonalDataCreate } from '@shared/api';
import { useUploadDocs } from '@shared/libs';
import { ComponentFormProps } from '@shared/types';

import styles from './styles.module.scss';

const defaultValues: UserPersonalDataCreate = {
  doc_scan_keys: [],
  firstname: '',
  patronymic: '',
  surname: '',
};

export const VerificationForm: FC<
  ComponentFormProps<UserPersonalDataCreate>
> = ({
  errors: apiErrors,
  initState,
  loading,
  onSubmit: formOnSubmit,
  setError,
}) => {
  const uploadDocs = useUploadDocs();
  const submitHandler = async (values: UserPersonalDataCreate) => {
    if (setError) {
      setError({});
    }
    setFormErrors({});
    try {
      const valuesForSubmit = (await verifySchema.validate(
        values,
      )) as UserPersonalDataCreate;

      formOnSubmit(valuesForSubmit);
    } catch (error: any) {
      setFormErrors({ [error.path]: error.errors });
    }
  };
  const {
    errors: currentErrors,
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    setBatchValues,
    setFormErrors,
    values,
  } = useForm<UserPersonalDataCreate>(submitHandler, {
    ...defaultValues,
    ...initState,
  });
  const errors = { ...currentErrors, ...apiErrors };

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <FieldLabel label="Фото документов">
        <UploadImage
          initValue={values.doc_scan_keys || []}
          uploadCallback={uploadDocs}
          resolution="1920 * 1080"
          maxCount={10}
          onChange={(val) => setBatchValues({ doc_scan_keys: val })}
        />
      </FieldLabel>
      <Spacer size={8} />
      <div className={styles.verFrom}>
        <TextField
          id="surname"
          name="surname"
          size="large"
          label="Фамилия"
          initValue={values.surname}
          onChange={onChange}
          fullWidth
          error={errors?.surname}
        />
        <Spacer size={12} />
        <TextField
          id="firstname"
          name="firstname"
          size="large"
          label="Имя"
          initValue={values.firstname}
          onChange={onChange}
          fullWidth
          error={errors?.firstname}
        />
        <Spacer size={12} />
        <TextField
          id="patronymic"
          name="patronymic"
          size="large"
          label="Отчество"
          initValue={values.patronymic}
          onChange={onChange}
          fullWidth
          error={errors?.patronymic}
        />
        <Spacer size={12} />
        <Button fullWidth type="submit">
          Подтвердить
        </Button>
      </div>

      <LoaderOverlay show={loading} />
    </form>
  );
};
