import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import {
  registerErrors,
  RegisterForm,
  UserCreateWebValues,
} from '@entities/Auth';
import { AuthFormWrapper } from '@features/Auth';
import { toaster, Typography } from '@house-booking/ui-kit';
import { sdk, UserCreateWeb } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export const RegisterLogin: FC = (): JSX.Element => {
  const { navigate } = useNavigate();
  const [errors, setErrors] = useState<
    Partial<Record<keyof UserCreateWebValues, string>>
  >({});
  const [createUser, { error, isLoading, isSuccess }] =
    sdk.useCreateWebUserUserCreatePostMutation();

  const handleSubmit = async (regData: UserCreateWeb) => {
    createUser({
      userCreateWeb: regData,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toaster.success({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Для активации аккаунта обратитесь к администратору
          </Typography>
        ),
        timeout: 6000,
        title: 'Аккант успешно создан',
      });
      navigate(RouterName.Login);
    }
    if (error && isFetchBaseQueryError(error)) {
      setErrors(registerErrors[error.data.error_code]);
    }
  }, [isSuccess, error]);

  return (
    <AuthFormWrapper
      title="Регистрация"
      formSlot={
        <RegisterForm
          errors={errors}
          setError={setErrors}
          onSubmit={handleSubmit}
          loading={isLoading}
          disclaimerSlot={
            <Typography variant="head-14">
              Уже есть аккаунт? <Link to={RouterName.Login}>Войти</Link>
            </Typography>
          }
        />
      }
    />
  );
};
