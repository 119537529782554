import { FC, useEffect } from 'react';

import { FilterItemWrapper } from '@features/House/ui/FilterItemWrapper/FilterItemWrapper';
import {
  Button,
  Maybe,
  Select,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { handleNumberInput } from '@shared/libs';

import styles from './styles.module.scss';

export interface HouseListFilter {
  id: Maybe<number>;
  region: Maybe<number>;
  area: Maybe<number>;
  block: Maybe<number>;
}
const initialValues: HouseListFilter = {
  area: null,
  block: null,
  id: null,
  region: null,
};

export interface AdminHouseListFilterProps {
  onChange(val: HouseListFilter): void;
}
export const AdminHouseListFilter: FC<AdminHouseListFilterProps> = ({
  onChange,
}) => {
  const { data } =
    sdk.useListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetQuery();

  const areas = data?.areas || [];
  const blocks = data?.blocks || [];
  const regions = data?.regions || [];
  const { setBatchValues, values } = useForm<HouseListFilter>(
    console.log,
    initialValues,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className={styles.houseListFilter}>
      <FilterItemWrapper
        filterSlot={
          <TextField
            value={values?.id ? String(values.id) : ''}
            onChange={(e) => setBatchValues({ id: +e.target.value || null })}
            onKeyPress={handleNumberInput}
            placeholder="Введите ID дома"
            fullWidth
          />
        }
        title="ID"
        slim
      />
      <FilterItemWrapper
        filterSlot={
          <Select
            fullWidth
            placeholder="Выберите регион"
            initValue={values.region!}
            customValue={values.region!}
            items={regions}
            disabled={regions.length === 0 || !!values.id}
            onChange={(val) => setBatchValues({ region: val as number })}
            hideOnSelect
          />
        }
        slim
        title="Регион"
      />
      <FilterItemWrapper
        filterSlot={
          <Select
            fullWidth
            placeholder="Выберите район"
            initValue={values.area!}
            customValue={values.area!}
            items={areas}
            disabled={areas.length === 0 || !!values.id}
            onChange={(val) => setBatchValues({ area: val as number })}
            hideOnSelect
          />
        }
        slim
        title="Район"
      />
      <FilterItemWrapper
        filterSlot={
          <Select
            fullWidth
            placeholder="Выберите блок"
            initValue={values.block!}
            customValue={values.block!}
            items={blocks}
            disabled={blocks.length === 0 || !!values.id}
            onChange={(val) => setBatchValues({ block: val as number })}
            hideOnSelect
          />
        }
        slim
        title="Блок"
      />
      <div className={styles.houseListFilter__btnWrap}>
        <Button
          onClick={() => {
            setBatchValues(initialValues);
          }}
          fullWidth>
          Сбросить
        </Button>
      </div>
    </div>
  );
};
