import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { ActionButtons } from '@/shared';
import { RouterName } from '@app/router';
import { AdminHouseForm } from '@entities/Admin';
import { LoaderOverlay, toaster, Typography } from '@house-booking/ui-kit';
import { HouseCreate, HouseUpdate, sdk } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export interface AdminHouseCreateProps {
  onCancel(): void;
}
export const AdminHouseCreate: FC<AdminHouseCreateProps> = ({ onCancel }) => {
  const { navigate } = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [
    createHouse,
    {
      data: resultCreateData,
      error: errorCreateData,
      isLoading: isLoadingUpdate,
    },
  ] = sdk.useCreateNewHouseHouseCreatePostMutation();
  const [createData, setCreateData] = useState<HouseUpdate>();
  const handleSave = () => {
    if (!createData) {
      return toaster.error({ title: 'Данные для сохранения отсутствуют' });
    }
    createHouse({ houseCreate: createData as HouseCreate });
  };

  useEffect(() => {
    if (resultCreateData) {
      navigate(RouterName.AdminHouses);
    } else if (errorCreateData) {
      toaster.error({
        contentSlot: isFetchBaseQueryError(errorCreateData) ? (
          <Typography variant="body-14" color="on-surface-primary-2">
            {errorCreateData.data.error_details}
          </Typography>
        ) : undefined,
        title: 'Ошибка создания дома',
      });
    }
  }, [resultCreateData, errorCreateData]);

  return (
    <div>
      <LoaderOverlay show={isLoadingUpdate} />
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          {
            disabled: hasError,
            label: 'Создать',
            onClick: handleSave,
          },
        ]}
      />
      <AdminHouseForm onChange={setCreateData} onChangeHasError={setHasError} />
    </div>
  );
};
