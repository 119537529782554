import { FC, PropsWithChildren } from 'react';

import { AdminFooter } from '../AdminFooter/AdminFooter';
import { AdminNavigation } from '../AdminNavigation/AdminNavigation';
import { Header } from '../Header';

import styles from './styles.module.scss';

export const AdminLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => (
  <div className={styles.admin}>
    <Header navigationSlot={<AdminNavigation />} />
    <div className={styles.admin__main}>
      <div className={styles.admin__content}>{children}</div>
      <AdminFooter />
    </div>
  </div>
);
