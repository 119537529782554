import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { useIsHouseEditor } from '@entities/Auth/libs/hooks';
import { LoaderOverlay } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { AdminLayout } from '@widgets/Layout';

export const AdminEditorLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const { isLoading } = sdk.useMeUserMeGetQuery();
  const isHouseEditor = useIsHouseEditor();

  if (isLoading) {
    return <LoaderOverlay show />;
  }
  if (!isHouseEditor) {
    return <Redirect to={RouterName.Admin} />;
  }

  return <AdminLayout>{children}</AdminLayout>;
};
