import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { AdminTariffCreate } from '@features/Admin';

export const AdminTariffCreateView: FC = () => {
  const { navigate } = useNavigate();

  const handleCancel = () => {
    navigate(RouterName.AdminTariffs);
  };

  return (
    <div>
      <Head title="Создание тарифа" onBack={handleCancel} />
      <AdminTariffCreate onCancel={handleCancel} />
    </div>
  );
};
