import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { HistoryNotFound, HouseHistoryCard } from '@entities/House';
import { LoaderOverlay, Pagination, Spacer } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

import styles from './styles.module.scss';

const pageSize = 9;

export const BookingHistory: FC = () => {
  const { navigate } = useNavigate();
  const { data: user } = sdk.useMeUserMeGetQuery();
  const [page, setPage] = useState<number>(1);
  const pagination = useMemo(
    () => ({ pagerOffset: (page - 1) * pageSize, pagerSize: pageSize }),
    [page],
  );
  const { data, isFetching } =
    sdk.useListBookingsWithFiltersAndPaginationBookingListGetQuery(
      {
        ...pagination,
        userId: user?.id,
      },
      { skip: !user?.id },
    );
  const pageCount = Math.ceil(Number(data?.edge.total) / pageSize);

  return (
    <>
      <LoaderOverlay show={isFetching} />
      {data?.requests?.length ? (
        <>
          <div className={styles.history__list}>
            {data?.requests.map((house) => (
              <HouseHistoryCard key={house.id} booking={house} />
            ))}
          </div>
          <Spacer size={8} />
          {pageCount > 1 && (
            <Pagination pageCount={pageCount} page={page} onChange={setPage} />
          )}
        </>
      ) : (
        <HistoryNotFound onSelect={() => navigate(RouterName.Filter)} />
      )}
      <Spacer size={16} />
    </>
  );
};
