import { FC } from 'react';

import { Button, Spacer, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface HistoryNotFoundProps {
  onSelect(): void;
}
export const HistoryNotFound: FC<HistoryNotFoundProps> = ({ onSelect }) => (
  <div className={styles.historyNotFound}>
    <div className={styles.historyNotFound__content}>
      <Typography variant="head-24">В истории бронирований пусто</Typography>
      <Spacer size={4} />
      <Typography variant="body-14">
        Посмотрите предложенные варианты и совершите первое бронирование
      </Typography>
      <Spacer size={8} />
      <Button onClick={onSelect}>Выбрать дом</Button>
    </div>
  </div>
);
