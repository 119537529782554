import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@house-booking/ui-kit';
import { AdminTariffsList } from '@widgets/Admin';

export const AdminTariffsView: FC = () => {
  const { navigate } = useNavigate();

  return (
    <div>
      <Head
        title="Список тарифов"
        rightSlot={
          <Button
            prependIcon={PlusDenseIcon}
            onClick={() => navigate(RouterName.AdminTariffCreate)}>
            Добавить тариф
          </Button>
        }
      />
      <AdminTariffsList />
    </div>
  );
};
