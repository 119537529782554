import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminActivateTariffModalProps {
  id: number;
}
declare global {
  interface ModalProps {
    activateTariff: AdminActivateTariffModalProps;
  }
}
export const AdminActivateTariffModal: FC<
  WithCloseModal<AdminActivateTariffModalProps>
> = ({ id, onClose }): JSX.Element => {
  const [activateTariff, { data, error }] =
    sdk.useSetTariffAvailableForUsingTariffActivatePostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Тариф успешно опубликован' });
      onClose();
    }
    if (error) {
      toaster.error({ title: 'Ошибка публикации тарифа' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите опубликовать тариф ${id}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button fullWidth onClick={() => activateTariff({ id })}>
              Подтвердить
            </Button>
          }
        />
      }
    />
  );
};
