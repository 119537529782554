import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminActivateHouseModalProps {
  id: number;
}
declare global {
  interface ModalProps {
    activateHouse: AdminActivateHouseModalProps;
  }
}
export const AdminActivateHouseModal: FC<
  WithCloseModal<AdminActivateHouseModalProps>
> = ({ id, onClose }): JSX.Element => {
  const [activateHouse, { data, error }] =
    sdk.useSetHouseAvailableForBookingHouseActivatePostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Дом успешно опубликован' });
      onClose();
    }
    if (error) {
      toaster.error({ title: 'Ошибка публикации дома' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите опубликовать дом ${id}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button fullWidth onClick={() => activateHouse({ id })}>
              Подтвердить
            </Button>
          }
        />
      }
    />
  );
};
