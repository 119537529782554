import { FC } from 'react';

import { Button, Spacer, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface HousesNotFoundProps {
  onReset(): void;
}
export const HousesNotFound: FC<HousesNotFoundProps> = ({ onReset }) => (
  <div className={styles.housesNotFound}>
    <div className={styles.housesNotFound__content}>
      <Typography variant="head-24">
        К сожалению, по вашему запросу ничего не найдено
      </Typography>
      <Spacer size={4} />
      <Typography variant="body-14">
        Попробуйте изменить параметры поиск
      </Typography>
      <Spacer size={8} />
      <Button onClick={onReset}>Сбросить фильтр</Button>
    </div>
  </div>
);
