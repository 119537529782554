import { FC } from 'react';
import cx from 'classnames';

import { Typography } from '@house-booking/ui-kit';
import { BookingStatusEnum } from '@shared/api';

import styles from './styles.module.scss';

export interface HouseHistoryStatusProps {
  status: BookingStatusEnum;
  className?: string;
}
export const HouseHistoryStatus: FC<HouseHistoryStatusProps> = ({
  className,
  status,
}) => (
  <Typography
    className={cx(
      styles.statusHouse,
      styles[`statusHouse_${status}`],
      className,
    )}
    color="neutrals-1"
    variant="head-14">
    {status === BookingStatusEnum.Pending && 'Ожидает оплаты'}
    {status === BookingStatusEnum.Paid && 'Оплачено'}
    {status === BookingStatusEnum.CheckedIn && 'Проверяется'}
    {status === BookingStatusEnum.Completed && 'Выполнен'}
    {status === BookingStatusEnum.CanceledByUser && 'Отменён'}
    {status === BookingStatusEnum.Refunded && 'Возвращён'}
    {status === BookingStatusEnum.CanceledByPaymentTimeout &&
      'Истек срок оплаты'}
  </Typography>
);
