import { FC } from 'react';
import cx from 'classnames';

import { Card } from '@/shared';
import { LoaderOverlay } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { HousePreview } from '@widgets/House/ui/HousePreview/HousePreview';

import styles from './styles.module.scss';

export interface BookingHouseInfoProps {
  bookingId: number;
}
export const BookingHouseInfo: FC<BookingHouseInfoProps> = ({ bookingId }) => {
  const { data, isLoading: isLoadingBooking } =
    sdk.useGetBookingByIdBookingGetIdGetQuery({
      id: bookingId,
    });
  const { data: house, isLoading: isLoadingHouse } =
    sdk.useGetHouseByIdHouseGetIdGetQuery(
      { id: Number(data?.house_id) },
      { skip: !data },
    );
  const isLoading = isLoadingBooking || isLoadingHouse;

  return (
    <Card className={styles.bookingHouseInfo}>
      {house || !isLoading ? (
        <>
          <HousePreview
            house={house!}
            className={cx(styles.card__block, styles['card__block--main'])}
          />
        </>
      ) : (
        <LoaderOverlay show />
      )}
    </Card>
  );
};
