import { FC } from 'react';

import { Container } from '@/shared';
import { Spacer, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

interface AuthFormWrapperProps {
  formSlot: JSX.Element;
  title: string;
}
export const AuthFormWrapper: FC<AuthFormWrapperProps> = ({
  formSlot,
  title,
}): JSX.Element => (
  <div className={styles.auth}>
    <Container>
      <Spacer size={8} />
      <div className={styles.auth__wrapper}>
        <div className={styles.auth__content}>
          <Typography
            className={styles.auth__title}
            variant="head-24"
            color="on-surface-primary-1">
            {title}
          </Typography>
          <Spacer size={8} />
          <div className={styles.auth__form}>{formSlot}</div>
        </div>
      </div>
    </Container>
  </div>
);
