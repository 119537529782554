import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  MoreIcon,
  Typography,
} from '@house-booking/ui-kit';
import { useModal } from '@shared/libs';

interface AdminTariffListItemSettingsProps {
  available?: boolean;
  id: number;
}

export const AdminTariffListItemSettings: FC<
  AdminTariffListItemSettingsProps
> = ({ available, id }) => {
  const { navigate } = useNavigate();
  const openRemoveModal = useModal('removeTariff');
  const openActivateModal = useModal('activateTariff');
  const openDeactivateModal = useModal('deactivateTariff');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const actionCallback = (callback: () => void) => () => {
    setIsOpen(false);
    callback();
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button icon prependIcon={MoreIcon} variant="text" size="large" />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <DropdownList maxHeight={140}>
        {available ? (
          <DropdownItem
            fullWidth
            onClick={actionCallback(() => openDeactivateModal({ id }))}>
            <Typography variant="body-16">Снять с публикации</Typography>
          </DropdownItem>
        ) : (
          <DropdownItem
            fullWidth
            onClick={actionCallback(() => openActivateModal({ id }))}>
            <Typography variant="body-16">Опубликовать</Typography>
          </DropdownItem>
        )}
        <DropdownItem
          fullWidth
          onClick={actionCallback(() =>
            navigate(RouterName.AdminTariffEdit, { id }),
          )}>
          <Typography variant="body-16">Редактировать</Typography>
        </DropdownItem>
        <DropdownItem
          fullWidth
          onClick={actionCallback(() => openRemoveModal({ id }))}>
          <Typography variant="body-16" color="error-2">
            Удалить
          </Typography>
        </DropdownItem>
      </DropdownList>
    </Dropdown>
  );
};
