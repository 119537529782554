import { FC } from 'react';

import { RegisterLogin } from '@widgets/Auth';

import styles from './styles.module.scss';

export const RegisterView: FC = (): JSX.Element => (
  <div className={styles.registerView}>
    <RegisterLogin />
  </div>
);
