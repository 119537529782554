import { useEffect } from 'react';

import { toaster, Typography } from '@house-booking/ui-kit';
import { sdk, UserPersonalDataCreate } from '@shared/api';

export const useVerifyUser = (): [
  (values: UserPersonalDataCreate) => void,
  { isLoading: boolean },
] => {
  const [verifyUser, { data, error, isLoading }] =
    sdk.useAddUserPersonalDataUserAddPersonalDataPostMutation();
  const handleSubmit = (values: UserPersonalDataCreate) => {
    verifyUser({
      userPersonalDataCreate: values,
    });
  };

  useEffect(() => {
    if (data) {
      toaster.warning({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Ожидайте подтверждения Ваших данных администратором
          </Typography>
        ),
        title: 'Аккаунт отправлен на верификацию',
      });
    }
    if (error) {
      toaster.error({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Попробуйте позже или обратитесь к администратору
          </Typography>
        ),
        title: 'Ошибка верификации аккаунта',
      });
    }
  }, [data, error]);

  return [handleSubmit, { isLoading }];
};
