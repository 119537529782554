import { FC, useEffect, useState } from 'react';

import { ActionButtons } from '@/shared';
import { AdminTariffForm } from '@entities/Admin';
import { LoaderOverlay, toaster, Typography } from '@house-booking/ui-kit';
import { sdk, TariffUpdate } from '@shared/api';
import { useModal } from '@shared/libs';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export interface AdminTariffEditProps {
  id: number;
  onCancel(): void;
}
export const AdminTariffEdit: FC<AdminTariffEditProps> = ({ id, onCancel }) => {
  const openActivateModal = useModal('activateTariff');
  const openDeactivateModal = useModal('deactivateTariff');
  const [hasError, setHasError] = useState<boolean>(false);
  const { data } = sdk.useGetTariffByIdTariffGetIdGetQuery({
    id: +id,
  });
  const [
    updateTariff,
    {
      data: resultUpdateData,
      error: errorUpdateData,
      isLoading: isLoadingUpdate,
    },
  ] = sdk.useUpdateTariffTariffUpdatePostMutation();
  const [updateData, setUpdateData] = useState<TariffUpdate>();
  const handleSave = () => {
    if (!updateData) {
      return toaster.error({ title: 'Данные для сохранения отсутствуют' });
    }
    updateTariff({ tariffUpdate: updateData });
  };

  useEffect(() => {
    if (resultUpdateData) {
      toaster.success({ title: 'Тарифф успешно сохранён' });
    } else if (errorUpdateData) {
      toaster.error({
        contentSlot: isFetchBaseQueryError(errorUpdateData) ? (
          <Typography variant="body-14" color="on-surface-primary-2">
            {errorUpdateData.data.error_details}
          </Typography>
        ) : undefined,
        title: 'Ошибка сохранения тарифа',
      });
    }
  }, [resultUpdateData, errorUpdateData]);

  if (!data) {
    return <></>;
  }

  return (
    <div>
      <LoaderOverlay show={isLoadingUpdate} />
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          data.is_active
            ? {
                disabled: hasError,
                label: 'Снять с публикации',
                onClick: () => openDeactivateModal({ id }),
              }
            : {
                disabled: hasError,
                label: 'Опубликовать',
                onClick: () => openActivateModal({ id }),
              },
          {
            disabled: hasError,
            label: 'Сохранить изменения',
            onClick: handleSave,
          },
        ]}
      />
      <AdminTariffForm
        initialValues={data as TariffUpdate}
        onChange={setUpdateData}
        onChangeHasError={setHasError}
      />
    </div>
  );
};
