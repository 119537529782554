import { FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

interface FilterItemWrapperProps {
  error?: string;
  title: string;
  filterSlot: JSX.Element;
  className?: string;
  slim?: boolean;
}
export const FilterItemWrapper: FC<FilterItemWrapperProps> = ({
  error,
  filterSlot,
  slim,
  title,
}): JSX.Element => (
  <div className={cx(styles.filterItem, slim && styles.filterItem_slim)}>
    <div className={styles.filterItem__head}>
      <div className={styles.filterItem__title}>{title}</div>
      <span className={styles.filterItem__error}>{error}</span>
    </div>

    {filterSlot}
  </div>
);
