import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { LoaderOverlay, TableController } from '@house-booking/ui-kit';
import { sdk, UserAwaitingConfirmationAdminViewResponse } from '@shared/api';
import { pagination } from '@shared/libs';

const headers = [
  { key: true, title: 'ID', value: 'id', width: 60 },
  { title: 'Email', value: 'email' },
  { title: 'ФИО', value: 'fio' },
];

export const AdminUsersConfirmationTable: FC = () => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isFetching } =
    sdk.useListUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGetQuery(
      pagination(page, pageSize),
      { pollingInterval: 10_000 },
    );

  const rowTemplate = (user: UserAwaitingConfirmationAdminViewResponse) => ({
    email: user.email || '-',
    fio: `${user.surname} ${user.firstname} ${user.patronymic}`,
    id: user.id!,
  });
  const items = useMemo(
    () => (data?.users || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={isFetching} />
      <TableController
        data={items}
        headers={headers}
        total={data?.edge.total}
        countItems={[10, 25, 50]}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        pageSize={pageSize}
        onClickRow={(e, item) =>
          navigate(RouterName.AdminConfirmUser, { id: item.id })
        }
        counterPagination
        notResetPage
      />
    </Container>
  );
};
