import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@house-booking/ui-kit';

interface CancelBookingModalProps {
  onConfirm(): void;
}
declare global {
  interface ModalProps {
    cancelBooking: CancelBookingModalProps;
  }
}
export const CancelBookingModal: FC<
  WithCloseModal<CancelBookingModalProps>
> = ({ onClose, onConfirm }): JSX.Element => (
  <Dialog
    title="Вы действительно хотите отменить бронирование?"
    closed
    width={480}
    footerSlot={
      <DialogFooter
        fullWidth
        startSlot={
          <Button color="secondary" fullWidth onClick={onClose}>
            Отмена
          </Button>
        }
        endSlot={
          <Button
            fullWidth
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            Подтвердить
          </Button>
        }
      />
    }
  />
);
