import { FC } from 'react';

import { changePasswordSchema } from '@entities/Auth';
import {
  Button,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { SetPasswordUserSetPasswordPutApiArg } from '@shared/api';
import { ComponentFormProps } from '@shared/types';

const defaultValues: SetPasswordUserSetPasswordPutApiArg = {
  password: '',
  passwordConfirm: '',
};

export const PasswordForm: FC<
  ComponentFormProps<SetPasswordUserSetPasswordPutApiArg>
> = ({
  errors: apiErrors,
  initState,
  loading,
  onSubmit: formOnSubmit,
  setError,
}) => {
  const submitHandler = async (values: SetPasswordUserSetPasswordPutApiArg) => {
    if (setError) {
      setError({});
    }
    setFormErrors({});
    try {
      const valuesForSubmit = (await changePasswordSchema(
        values.password,
      ).validate(values)) as SetPasswordUserSetPasswordPutApiArg;

      formOnSubmit(valuesForSubmit);
    } catch (error: any) {
      setFormErrors({ [error.path]: error.errors });
    }
  };
  const {
    errors: currentErrors,
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    setFormErrors,
    values,
  } = useForm<SetPasswordUserSetPasswordPutApiArg>(submitHandler, {
    ...defaultValues,
    ...initState,
  });
  const errors = { ...currentErrors, ...apiErrors };

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <TextField
        id="password"
        name="password"
        type="password"
        size="large"
        label="Введите новый пароль"
        initValue={values.password}
        onChange={onChange}
        fullWidth
        error={errors?.password}
      />
      <Spacer size={12} />
      <TextField
        id="passwordConfirm"
        name="passwordConfirm"
        type="password"
        size="large"
        label="Повторите новый пароль"
        initValue={values.passwordConfirm}
        onChange={onChange}
        fullWidth
        error={errors?.passwordConfirm}
      />
      <Spacer size={12} />
      <Button fullWidth type="submit">
        Сменить пароль
      </Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
