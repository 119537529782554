import { Route } from 'react-justanother-router';

import { Role, RouterName } from '@app/router';
import { HouseDetailedView, HousesListView } from '@pages/House';
import { BaseLayout } from '@widgets/Layout';

export const houseRoutes: Route = {
  children: [
    {
      component: HouseDetailedView,
      name: RouterName.House,
      path: '/:id',
      props: {
        accessRole: Role.USER,
        layout: BaseLayout,
      },
    },
  ],
  component: HousesListView,
  name: RouterName.Filter,
  path: '/',
  props: {
    accessRole: Role.USER,
    layout: BaseLayout,
  },
};
