import React, { FC } from 'react';

import { Maybe, Typography } from '@house-booking/ui-kit';

export interface TableCellItemValueProps {
  label: string | JSX.Element;
  value?: Maybe<number | string | JSX.Element>;
}
export const TableCellLabelValue: FC<TableCellItemValueProps> = ({
  label,
  value,
}) => (
  <>
    <Typography variant="body-14" color="on-surface-primary-1">
      {label}
    </Typography>
    {value && (
      <Typography variant="body-12" color="on-surface-primary-2">
        {value}
      </Typography>
    )}
  </>
);

export const renderCellItemValue = (args: TableCellItemValueProps) => (
  <TableCellLabelValue {...args} />
);
