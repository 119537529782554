import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminRemoveTariffModalProps {
  id: number;
}
declare global {
  interface ModalProps {
    removeTariff: AdminRemoveTariffModalProps;
  }
}
export const AdminRemoveTariffModal: FC<
  WithCloseModal<AdminRemoveTariffModalProps>
> = ({ id, onClose }): JSX.Element => {
  const [deleteTariff, { data, error }] =
    sdk.useDeleteTariffTariffDeletePostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Тариф успешно удалён' });
      onClose();
    }
    if (error) {
      toaster.error({ title: 'Ошибка удаления тарифа' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите удалить тариф ${id}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              color="danger"
              onClick={() => deleteTariff({ id })}>
              Подтвердить
            </Button>
          }
        />
      }
    />
  );
};
