import { AuthProvider } from 'react-auth-kit';
import { Router } from 'react-justanother-router';
// @ts-ignore
import { LightgalleryProvider } from 'react-lightgallery';

import {
  AdminActivateHouseModal,
  AdminActivateTariffModal,
  AdminDeactivateHouseModal,
  AdminDeactivateTariffModal,
  AdminRemoveHouseModal,
  AdminRemoveTariffModal,
  AdminVerifyUserModal,
} from '@entities/Admin';
import { VerifyAlertModal } from '@entities/Auth';
import { CancelBookingModal } from '@entities/Booking';
import { useRefresh } from '@features/Auth';
import { BookingHouseModal } from '@features/House';
import { UserConfirmModal } from '@features/User';
import {
  GoblinProvider,
  ModalProviderProps,
  Toaster,
} from '@house-booking/ui-kit';
import { ACCESS_TOKEN_COOKIE_KEY } from '@shared/const';

import './configs';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { history, renderer, router } from './router';

import 'react-rangeslider/lib/index.css';
import 'lightgallery.js/dist/css/lightgallery.css';
import './styles/index.scss';
import 'swiper/css';

const componentDictionary: ModalProviderProps['components'] = {
  activateHouse: AdminActivateHouseModal,
  activateTariff: AdminActivateTariffModal,
  bookingHouse: BookingHouseModal,
  cancelBooking: CancelBookingModal,
  confirmChangePassword: UserConfirmModal,
  deactivateHouse: AdminDeactivateHouseModal,
  deactivateTariff: AdminDeactivateTariffModal,
  removeHouse: AdminRemoveHouseModal,
  removeTariff: AdminRemoveTariffModal,
  verifyAlert: VerifyAlertModal,
  verifyUser: AdminVerifyUserModal,
};

export const Root = () => {
  const refresh = useRefresh();

  return (
    <LightgalleryProvider>
      <AuthProvider
        authType={'cookie'}
        authName={ACCESS_TOKEN_COOKIE_KEY}
        refresh={refresh}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === 'https:'}>
        <GoblinProvider modalComponents={componentDictionary}>
          <Router history={history} router={router} renderer={renderer}>
            <Toaster />
          </Router>
        </GoblinProvider>
      </AuthProvider>
    </LightgalleryProvider>
  );
};
