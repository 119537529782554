import { createGoblin } from '@house-booking/ui-kit';

createGoblin({
  initLang: 'ru',
  theme: {
    mode: {
      light: {
        colors: {
          'primary-1': '#579995',
          'primary-2': '#336e6c',
          'primary-3': '#275752',
        },
      },
    },
  },
});
