import { FC } from 'react';

import { Breadcrumbs } from '@/shared';
import { RouterName } from '@app/router';
import { LoaderOverlay, Typography } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { HouseDetailedCard } from '@widgets/House';

import styles from './styles.module.scss';

interface DetailedInfoProps {
  params: { id: string };
}
export const HouseDetailedView: FC<DetailedInfoProps> = ({
  params,
}): JSX.Element => {
  const { data, isLoading } =
    sdk.useGetHouseByIdWithExtendedTariffInfoHouseGetExtIdGetQuery({
      id: +params.id,
    });

  if (isLoading) {
    return <LoaderOverlay show />;
  }
  if (!data) {
    return <Typography variant="head-24">Дом не найден</Typography>;
  }
  const breadcrumbs = [
    { link: RouterName.Filter, title: 'Список домов' },
    { link: '/', title: String(params.id) },
  ];

  return (
    <div className={styles.houseView}>
      <HouseDetailedCard
        house={data}
        topSlot={<Breadcrumbs items={breadcrumbs} />}
      />
    </div>
  );
};
