import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { loginSchema } from '@entities/Auth/const';
import {
  Button,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { BodyLoginAuthSignInPost } from '@shared/api';
import { ComponentFormProps } from '@shared/types';

import styles from './styles.module.scss';

const defaultValues: BodyLoginAuthSignInPost = {
  password: '',
  username: '',
};

export interface SignInFormProps {
  disclaimerSlot?: JSX.Element;
}

export const SignInForm: FC<
  ComponentFormProps<BodyLoginAuthSignInPost, SignInFormProps>
> = ({
  disclaimerSlot,
  errors: apiErrors,
  initState,
  loading,
  onSubmit: formOnSubmit,
  setError,
}) => {
  const [data, setData] = useState<BodyLoginAuthSignInPost>();
  const submitHandler = async (values: BodyLoginAuthSignInPost) => {
    setData(values);
    if (setError) {
      setError({});
    }
    setFormErrors({});
    try {
      const valuesForSubmit = (await loginSchema.validate(
        values,
      )) as BodyLoginAuthSignInPost;

      formOnSubmit(valuesForSubmit);
    } catch (error: any) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const {
    errors: currentErrors,
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    setFormErrors,
    values,
  } = useForm<BodyLoginAuthSignInPost>(submitHandler, {
    ...defaultValues,
    ...initState,
  });
  const errors = { ...currentErrors, ...apiErrors };
  const disabledBtn = JSON.stringify(data) === JSON.stringify(values);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <TextField
        id="username"
        name="username"
        size="large"
        placeholder="Логин"
        initValue={values.username}
        onChange={onChange}
        error={errors?.username}
        fullWidth
        autoFocus
      />
      <Spacer size={12} />
      <TextField
        id="password"
        name="password"
        type="password"
        size="large"
        placeholder="Пароль"
        initValue={values.password}
        onChange={onChange}
        fullWidth
        error={errors?.password}
      />
      <Spacer size={12} />
      {disclaimerSlot}
      <Spacer size={8} />
      <Button
        className={styles.form__button}
        size={isMobile ? 'large' : 'default'}
        disabled={disabledBtn}
        type="submit">
        Войти
      </Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
