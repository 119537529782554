import { FC } from 'react';

import { Container } from '@/shared';
import { BookingHouseInfo, BookingMainInfo } from '@widgets/Booking';
import { HouseOperations } from '@widgets/House';

import styles from './styles.module.scss';

export interface HistoryDetailViewProps {
  params: {
    id: string;
  };
}
export const HistoryDetailView: FC<HistoryDetailViewProps> = ({
  params: { id },
}) => (
  <Container className={styles.historyDetail}>
    <div className={styles.historyDetail__content}>
      <div className={styles.historyDetail__main}>
        <BookingHouseInfo bookingId={+id} />
        <BookingMainInfo bookingId={+id} />
      </div>
      <HouseOperations bookingId={+id} />
    </div>
  </Container>
);
