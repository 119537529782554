import { FC } from 'react';

import { Container } from '@/shared';
import { ArrowLeftIcon, Button, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface HeadProps {
  title: string;
  rightSlot?: JSX.Element;
  onBack?(): void;
}
export const Head: FC<HeadProps> = ({ onBack, rightSlot, title }) => (
  <Container className={styles.head}>
    <div className={styles.head__left}>
      {!!onBack && (
        <Button
          icon
          prependIcon={ArrowLeftIcon}
          onClick={onBack}
          color={'secondary'}
        />
      )}

      <Typography variant="head-32" color="on-surface-primary-1">
        {title}
      </Typography>
    </div>
    {rightSlot && <div className={styles.head__right}>{rightSlot}</div>}
  </Container>
);
