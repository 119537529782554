import { FC, useMemo } from 'react';

import { Card, ListItem } from '@/shared';
import { BookingActions } from '@entities/Booking';
import { HouseHistoryStatus } from '@entities/House/ui/HouseHistoryStatus/HouseHistoryStatus';
import { LoaderOverlay, Spacer, Typography } from '@house-booking/ui-kit';
import { HouseStatusEnum, sdk } from '@shared/api';
import { formatAmount } from '@shared/libs';

import styles from './styles.module.scss';

export interface BookingMainInfoProps {
  bookingId: number;
}
export const BookingMainInfo: FC<BookingMainInfoProps> = ({ bookingId }) => {
  const { data, isLoading: isLoadingBooking } =
    sdk.useGetBookingByIdBookingGetIdGetQuery({
      id: bookingId,
    });
  const { data: house, isLoading: isLoadingHouse } =
    sdk.useGetHouseByIdHouseGetIdGetQuery(
      { id: Number(data?.house_id) },
      { skip: !data },
    );
  const isLoading = isLoadingBooking || isLoadingHouse;

  const houseState = useMemo(() => {
    if (
      house?.status === HouseStatusEnum.Pause ||
      house?.status === HouseStatusEnum.Close
    ) {
      return 'Дом закрыт';
    }
    if (house?.status === HouseStatusEnum.Open) {
      return 'Дом открыт';
    }

    return null;
  }, [house?.status]);

  return (
    <Card className={styles.bookingMainInfo}>
      {isLoading ? (
        <LoaderOverlay />
      ) : data ? (
        <>
          <Typography variant="head-24">{data?.id}</Typography>
          {data.status && (
            <HouseHistoryStatus
              className={styles.bookingMainInfo__status}
              status={data.status}
            />
          )}
          <Spacer size={8} />
          <div className={styles.bookingMainInfo__info}>
            <ListItem label="Дата заезда" value={data.start_date!} />
            <ListItem label="Дата отъезда" value={data.end_date!} />
            <ListItem label="Дней пребывания" value={data.duration_days!} />
            <ListItem
              label="Общая стоимость"
              value={`${formatAmount(data.price!, true)} ₽`}
            />
            {houseState && <ListItem label="Состояние" value={houseState} />}
          </div>
          <Spacer size={8} />
          {house && <BookingActions booking={data} house={house} />}
        </>
      ) : (
        <Typography variant="head-24">Бронирование не найдено</Typography>
      )}
    </Card>
  );
};
