import { FC, useEffect, useState } from 'react';

import {
  Button,
  PlusIcon,
  Spacer,
  TextField,
  TrashIcon,
} from '@house-booking/ui-kit';

export interface MultiTextFieldProps {
  initValue: string[];
  onChange(val: string[]): void;
}
interface MultiTextFieldValue {
  value: string;
  key: string;
}
export const MultiTextField: FC<MultiTextFieldProps> = ({
  initValue,
  onChange,
}) => {
  const [value, setValue] = useState<MultiTextFieldValue[]>(
    initValue.map((el) => ({ key: crypto.randomUUID(), value: el })),
  );
  const removeItem = (key: string) => {
    setValue((state) => state.filter((value) => value.key !== key));
  };
  const addItem = () => {
    setValue((state) => [...state, { key: crypto.randomUUID(), value: '' }]);
  };

  const updateItem = (value: string, key: string) => {
    setValue((state) => {
      const newState = [...state];
      const updateIndex = newState.findIndex((el) => el.key === key);

      newState[updateIndex].value = value;

      return newState;
    });
  };

  useEffect(() => {
    onChange(value.map((el) => el.value));
  }, [value]);

  return (
    <div>
      {value.map((value) => (
        <>
          <TextField
            size="large"
            key={value.key}
            initValue={value.value}
            fullWidth
            onChange={(e) => updateItem(e.target.value, value.key)}
            appendSlot={
              <TrashIcon
                className="cursor-pointer"
                onClick={() => removeItem(value.key)}
              />
            }
          />
          <Spacer size={8} />
        </>
      ))}
      <Button
        size="small"
        variant="outlined"
        onClick={addItem}
        prependIcon={PlusIcon}>
        Добавить
      </Button>
    </div>
  );
};
