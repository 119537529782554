import { FC } from 'react';

import { Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface AdminHouseHeadInfoProps {
  id: string | number;
  available: boolean;
}
export const AdminHouseHeadInfo: FC<AdminHouseHeadInfoProps> = ({
  available,
  id,
}) => (
  <div className={styles.houseHeadInfo}>
    <Typography variant={'body-16'} color={'on-surface-primary-1'}>
      Статус: {available ? 'Опубликован' : 'Не опубликован'}
    </Typography>
    <Typography variant={'body-16'} color={'on-surface-primary-1'}>
      ID: {id}
    </Typography>
  </div>
);
