import { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { Card, ListItem } from '@/shared';
import { RouterName } from '@app/router';
import { Button, Spacer, Typography } from '@house-booking/ui-kit';
import { HouseWithExtendedTariffs } from '@shared/api';
import { formatAmount, getMinPrice } from '@shared/libs/utils/format';

import styles from './styles.module.scss';

export const HouseMiniCard: FC<HouseWithExtendedTariffs> = ({
  area_id,
  available,
  block_id,
  id,
  image_links,
  region_id,
  room,
  tariffs,
}): JSX.Element => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const price = getMinPrice(tariffs);
  const slideTo = (i: number) => {
    swiper?.slideTo(i, 0);
  };
  const { navigate } = useNavigate();
  const isTablet = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const handleNavigate = () => {
    if (!available) {
      return;
    }
    navigate(RouterName.House, { id });
  };

  return (
    <Card
      className={cx(styles.card, !available && styles.card_booked)}
      onClick={handleNavigate}>
      <div className={styles.card__images}>
        <Swiper slidesPerView={'auto'} onSwiper={(swiper) => setSwiper(swiper)}>
          {(image_links || []).map((image, i) => (
            <SwiperSlide key={i}>
              <img className={styles.card__image} src={image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.card__images_hover}>
          {(image_links || []).map((_, i) => (
            <div
              className={styles.card__images_hover_item}
              key={i}
              onMouseEnter={() => slideTo(i)}></div>
          ))}
        </div>
      </div>
      <div className={styles.card__main}>
        <Typography
          className={styles.card__title}
          color="primary-1"
          variant="head-20">
          {id}
        </Typography>
        <Spacer size={8} />
        <div className={styles.card__btm}>
          <ListItem label="Регион" value={String(region_id)} />
          <ListItem label="Область" value={String(area_id)} />
          <ListItem label="Блок" value={String(block_id)} />
          <ListItem label="Комната" value={String(room)} />
        </div>
        <div className={styles.card__control}>
          <div className={styles.card__price}>
            {available ? `От ${formatAmount(price, true)} ₽` : 'Занято'}
          </div>
          {available && (
            <Button size="large" fullWidth={isTablet}>
              Подробнее
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
