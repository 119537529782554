import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

interface CardProps {
  className?: string;
  onClick?(): void;
}
export const Card: FC<PropsWithChildren<CardProps>> = ({
  children,
  className,
  onClick,
}) => (
  <div className={cx(styles.card, className)} onClick={onClick}>
    {children}
  </div>
);
