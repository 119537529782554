import { FC, useState } from 'react';

import { Container } from '@/shared';
import {
  AdminHouseListFilter,
  AdminHousesTable,
  HouseListFilter,
} from '@features/Admin';
import { Divider, Spacer, TabItem, Tabs } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { pagination } from '@shared/libs';

export enum HousesTabs {
  All,
  Active,
}
export const AdminHousesList: FC = () => {
  const { data: dataAll } =
    sdk.useListHousesForAdminWithPaginationHouseAdminViewListGetQuery(
      pagination(1, 1),
    );
  const { data: dataAvailable } =
    sdk.useListHousesForAdminWithPaginationHouseAdminViewListGetQuery({
      ...pagination(1, 1),
      available: true,
    });
  const [tab, setTab] = useState<HousesTabs>(HousesTabs.All);
  const [filters, setFilters] = useState<HouseListFilter>();

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem
            value={HousesTabs.All}
            label={`Все (${dataAll?.edge.total || 0})`}
          />
          <TabItem
            value={HousesTabs.Active}
            label={`Доступные (${dataAvailable?.edge.total || 0})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <AdminHouseListFilter onChange={setFilters} />
      </Container>
      <Spacer size={12} />
      <AdminHousesTable
        onlyAvailable={tab === HousesTabs.Active}
        filters={filters}
      />
    </div>
  );
};
