import { KeyboardEvent } from 'react';

export const handleNumberInput = (
  event: KeyboardEvent<HTMLInputElement>,
  decimal?: boolean,
) => {
  if (decimal) {
    const input = event.currentTarget.value;
    const isDecimalPoint = event.key === '.';

    if (!/\d/.test(event.key) && !isDecimalPoint) {
      event.preventDefault();
    }

    if (isDecimalPoint && input.includes('.')) {
      event.preventDefault();
    }
  } else {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }
};
