import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@house-booking/ui-kit';

interface UserConfirmModalProps {
  onSuccess(): void;
}
declare global {
  interface ModalProps {
    confirmChangePassword: UserConfirmModalProps;
  }
}
export const UserConfirmModal: FC<WithCloseModal<UserConfirmModalProps>> = ({
  onClose,
  onSuccess,
}): JSX.Element => (
  <Dialog
    title="Вы действительно хотите сменить пароль?"
    closed
    width={480}
    footerSlot={
      <DialogFooter
        fullWidth
        startSlot={
          <Button color="secondary" fullWidth onClick={onClose}>
            Отмена
          </Button>
        }
        endSlot={
          <Button
            fullWidth
            onClick={() => {
              onSuccess();
              onClose();
            }}>
            Подтвердить
          </Button>
        }
      />
    }
  />
);
