import { Route } from 'react-justanother-router';

import { PageNotFound } from '@pages/Static';

import { RouterName } from '../types';

import { adminRoutes } from './admin';
import { authRoutes } from './auth';
import { houseRoutes } from './house';
import { profileRoutes } from './profile';

export const routes: Route[] = [
  adminRoutes,
  authRoutes,
  profileRoutes,
  houseRoutes,
  {
    component: PageNotFound,
    name: RouterName.NotFound,
    path: '(.*)',
  },
];
