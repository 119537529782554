import { FC, useEffect, useState } from 'react';
import Slider from 'react-rangeslider';
import cx from 'classnames';
import { useDebounce } from 'use-debounce';

import { TextField } from '@house-booking/ui-kit';
import { formatAmount } from '@shared/libs';

import styles from './styles.module.scss';

interface RangeInputProps {
  value: number | undefined;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
  placeholder: string;
}
export const RangeInput: FC<RangeInputProps> = ({
  max,
  min,
  onChange,
  placeholder,
  step,
  value: curValue,
}): JSX.Element => {
  const [value, setValue] = useState(min);
  const [debouncedValue] = useDebounce(value, 1000);

  useEffect(() => {
    if (debouncedValue) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValue(curValue || 0);
  }, [curValue]);

  return (
    <div className={styles.range}>
      <TextField
        fullWidth
        value={value ? `до ${formatAmount(value)} ₽ за день` : ''}
        placeholder={placeholder}
        readonly
        onChange={(e) => setValue(e.target.valueAsNumber)}
      />
      <Slider
        className={cx(styles.range__input, styles.slider)}
        min={min}
        max={max}
        step={step}
        value={value}
        tooltip={false}
        onChange={(value) => setValue(value)}
      />
    </div>
  );
};
