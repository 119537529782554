import { Route } from 'react-justanother-router';

import { Role, RouterName } from '@app/router';
import { AuthView, LogoutView, RegisterView } from '@pages/Auth';
import { AuthLayout, BaseLayout } from '@widgets/Layout';

export const authRoutes: Route = {
  children: [
    {
      component: AuthView,
      name: RouterName.Login,
      path: '/login',
      props: {
        accessRole: Role.GHOST,
        layout: AuthLayout,
      },
    },
    {
      component: RegisterView,
      name: RouterName.Register,
      path: '/register',
      props: {
        accessRole: Role.GHOST,
        layout: AuthLayout,
      },
    },
    {
      component: LogoutView,
      name: RouterName.Logout,
      path: '/logout',
      props: {
        accessRole: Role.USER,
        layout: BaseLayout,
      },
    },
  ],
  path: '/auth',
};
