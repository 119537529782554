import { FC } from 'react';
import { Link } from 'react-justanother-router';

import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export const PageNotFound: FC = () => (
  <div className={styles.staticPage404}>
    <div className={styles.staticPage404__textWrapper}>
      <h1 className={styles.staticPage404__title}>404</h1>
      <h3 className={styles.staticPage404__subtitle}>Страница не найдена</h3>
    </div>

    <div className={styles.staticPage404__footer}>
      <p>Вернуться назад в</p>
      <Link className={styles.staticPage404__link} to={RouterName.Filter}>
        список доступных домов
      </Link>
    </div>
  </div>
);
