import { Mutex } from 'async-mutex';

import { history, router, RouterName } from '@app/router';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { RefreshTokenAuthRefreshTokenPostApiResponse } from '@shared/api/sdkGenerated';
import { AllBaseQueryFn } from '@shared/api/types';
import { ACCESS_TOKEN_COOKIE_KEY } from '@shared/const/auth.const';
import { getCookie, removeCookie, setCookie } from '@shared/libs';

const setAuthorizationHeaders = (headers: Headers, token: string) =>
  headers.set('authorization', `Bearer ${token}`);

export const baseQuery = <T>(baseURL: string) =>
  fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      const token = getCookie(ACCESS_TOKEN_COOKIE_KEY);

      if (token) {
        setAuthorizationHeaders(headers, token);
      }

      return headers;
    },
  }) as T;

const mutex = new Mutex();

export const baseQueryWithRefresh =
  <T extends AllBaseQueryFn>(baseURL: string): T =>
  // @ts-ignore
  async (args: FetchArgs, api, extraOptions) => {
    await mutex.waitForUnlock();

    let result = await baseQuery<T>(baseURL)(args, api, extraOptions);

    if (
      result.error &&
      result.error.status === 401 &&
      args.url !== '/auth/sign_in'
    ) {
      if (mutex.isLocked()) {
        await mutex.waitForUnlock();
        result = await baseQuery<T>(baseURL)(args, api, extraOptions);
      } else {
        const release = await mutex.acquire();

        try {
          const token = getCookie(ACCESS_TOKEN_COOKIE_KEY);

          if (!token) {
            return;
          }
          const refreshResult = (await baseQuery<T>(baseURL)(
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
              method: 'POST',
              url: '/auth/refresh_token',
            },
            {
              ...api,
              endpoint: 'refreshToken',
            },
            extraOptions,
          )) as { data: RefreshTokenAuthRefreshTokenPostApiResponse };

          if (refreshResult.data) {
            setCookie(
              ACCESS_TOKEN_COOKIE_KEY,
              refreshResult.data.access_token,
              {
                'max-age': 3600,
                secure: true,
              },
            );
            result = await baseQuery<T>(baseURL)(args, api, extraOptions);
          } else {
            removeCookie(ACCESS_TOKEN_COOKIE_KEY);
            history.navigate(router.urlFor(RouterName.Logout));
          }
        } finally {
          release();
        }
      }
    }

    return result;
  };
