import { FC } from 'react';
import { Link } from 'react-justanother-router';
import cx from 'classnames';

import styles from './styles.module.scss';

interface BreadcrumbsProps {
  items: {
    title: string;
    link: string;
  }[];
  theme?: 'white';
}
export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  theme,
}): JSX.Element => (
  <div
    className={cx(styles.breadcrumbs, {
      [styles['breadcrumbs--white']]: theme === 'white',
    })}>
    <ul className={styles.breadcrumbs__list}>
      {items.map((item, i) => (
        <li key={i} className={styles.breadcrumbs__item}>
          <Link className={styles.breadcrumbs__link} to={item.link}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
