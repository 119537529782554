import React, { useEffect, useState } from 'react';

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

interface MapComponentProps {
  height?: number;
  initValue: [number, number];
  onChange: (value: [number, number]) => void;
}

export const MapControl: React.FC<MapComponentProps> = ({
  height = 400,
  initValue,
  onChange,
}) => {
  const [coordinates, setCoordinates] = useState<[number, number]>(initValue);

  const handleMapClick = (event: any) => {
    const coords = event.get('coords') as [number, number];

    setCoordinates(coords);
    onChange(coords);
  };

  useEffect(() => {
    setCoordinates(initValue);
  }, [initValue]);

  return (
    <YMaps>
      <Map
        defaultState={{ center: coordinates, zoom: 10 }}
        style={{ borderRadius: 16, height, overflow: 'hidden', width: '100%' }}
        onClick={handleMapClick}>
        <Placemark
          geometry={coordinates}
          options={{
            iconColor: '#0095b6',
            preset: 'islands#icon',
          }}
        />
      </Map>
    </YMaps>
  );
};
