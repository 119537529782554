import { FC, useEffect } from 'react';
import { number, object, string } from 'yup';

import { Container, FieldLabel } from '@/shared';
import { TextField, useForm } from '@house-booking/ui-kit';
import { TariffUpdate } from '@shared/api';
import { handleNumberInput } from '@shared/libs';

import styles from './styles.module.scss';

export interface AdminTariffFormProps {
  onChange(values: Required<TariffUpdate>): void;
  onChangeHasError(value: boolean): void;
  initialValues?: TariffUpdate;
}
export const AdminTariffForm: FC<AdminTariffFormProps> = ({
  initialValues,
  onChange,
  onChangeHasError,
}) => {
  const schema = object().shape({
    cost_per_day: number().required('Цена обязательна'),
    description: string().required('Описание обязательно'),
    tariff_class: string().required('Поле класс обязательно'),
    tariff_name: string().required('Поле название обязательно'),
  });
  const { errors, setBatchValues, setFormErrors, values } =
    useForm<TariffUpdate>(
      console.log,
      (initialValues || {
        cost_per_day: 0,
        description: '',
        discount_pct: 0,
        tariff_class: '',
        tariff_name: '',
      }) as TariffUpdate,
    );

  const handleChange = async () => {
    setFormErrors({});
    onChangeHasError(false);
    try {
      onChange(
        // @ts-ignore
        await schema.validate(values as TariffUpdate, { abortEarly: false }),
      );
    } catch (error: any) {
      const allErrors = error.inner.reduce((acc: any, curr: any) => {
        acc[curr.path] = curr.errors;

        return acc;
      }, {});

      setFormErrors(allErrors);
      onChangeHasError(true);
    }
    onChange(values as Required<TariffUpdate>);
  };

  useEffect(() => {
    handleChange();
  }, [values]);

  return (
    <Container className={styles.houseForm}>
      <div className={styles.houseForm__fields}>
        <div className={styles.houseForm__row}>
          <FieldLabel label="Название">
            <TextField
              size="large"
              initValue={String(values.tariff_name)}
              error={errors.tariff_name}
              onChange={(e) => setBatchValues({ tariff_name: e.target.value })}
              fullWidth
            />
          </FieldLabel>
          <FieldLabel label="Класс">
            <TextField
              size="large"
              initValue={String(values.tariff_class)}
              error={errors.tariff_class}
              onChange={(e) => setBatchValues({ tariff_class: e.target.value })}
              fullWidth
            />
          </FieldLabel>
        </div>
        <div className={styles.houseForm__row}>
          <FieldLabel label="Стоимость за день">
            <TextField
              size="large"
              initValue={
                values?.cost_per_day ? String(values.cost_per_day) : ''
              }
              onChange={(e) =>
                setBatchValues({ cost_per_day: +e.target.value })
              }
              error={errors.cost_per_day}
              onKeyPress={(e) => handleNumberInput(e, true)}
              fullWidth
            />
          </FieldLabel>
          <FieldLabel label="Скидка в %">
            <TextField
              size="large"
              initValue={
                values?.discount_pct ? String(values.discount_pct * 100) : ''
              }
              onChange={(e) =>
                setBatchValues({
                  discount_pct:
                    +e.target.value > 0 && +e.target.value < 100
                      ? +e.target.value / 100
                      : 0,
                })
              }
              error={errors.discount_pct}
              onKeyPress={handleNumberInput}
              fullWidth
            />
          </FieldLabel>
        </div>
        <div>
          <FieldLabel label="Описание">
            <TextField
              size="large"
              initValue={String(values.description)}
              error={errors.description}
              onChange={(e) => setBatchValues({ description: e.target.value })}
              fullWidth
            />
          </FieldLabel>
        </div>
      </div>
    </Container>
  );
};
