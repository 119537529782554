interface CookieOptions {
  path?: string;
  [key: string]: any;
}

export const getCookie = (name: string): string | undefined => {
  const escapedName = name.replaceAll(
    /([$()*+./?[\\\]^{|}])/g,
    String.raw`\$1`,
  );
  const matches = document.cookie.match(`(?:^|; )${escapedName}=([^;]*)`);

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {},
): void => {
  const defaultOptions: CookieOptions = {
    path: '/',
  };

  options = { ...defaultOptions, ...options };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    if (!Object.prototype.hasOwnProperty.call(options, optionKey)) {
      continue;
    }
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];

    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = updatedCookie;
};

export const removeCookie = (name: string): void => {
  setCookie(name, '', {
    expires: new Date(0),
    path: '/',
  });
};
