import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminVerifyUserModalProps {
  id: number;
  fio: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    verifyUser: AdminVerifyUserModalProps;
  }
}
export const AdminVerifyUserModal: FC<
  WithCloseModal<AdminVerifyUserModalProps>
> = ({ fio, id, onClose, onSuccess }): JSX.Element => {
  const [verifyUser, { data, error }] =
    sdk.useActivateUserUserVerifyPostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Пользователь успешно верифицирован' });
      onClose();
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({ title: 'Ошибка верификации пользователя' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите верифицировать пользователя ${fio}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              onClick={() => verifyUser({ userVerify: { user_id: id } })}>
              Верифицировать
            </Button>
          }
        />
      }
    />
  );
};
