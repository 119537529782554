import axios from 'axios';

import { Maybe } from '@house-booking/ui-kit';
import {
  UploadImagesImagesUploadImagesPostApiArg,
  UploadImagesImagesUploadImagesPostApiResponse,
} from '@shared/api';
import { ACCESS_TOKEN_COOKIE_KEY } from '@shared/const';
import { getCookie } from '@shared/libs';
import { UploadCallbackItem } from '@shared/ui/UploadImage/UploadImage';

export const uploadImage = (
  params: UploadImagesImagesUploadImagesPostApiArg,
): Promise<UploadImagesImagesUploadImagesPostApiResponse> => {
  const formData = new FormData();

  if (params.bodyUploadImagesImagesUploadImagesPost.images) {
    for (const el of params.bodyUploadImagesImagesUploadImagesPost.images) {
      formData.append('images', el);
    }
  }

  // @ts-ignore
  return axios
    .post('/api/api_v1/images/upload_images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${getCookie(ACCESS_TOKEN_COOKIE_KEY)}`,
      },
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const useAdminUploadImage =
  () =>
  async (files: File[]): Promise<Maybe<UploadCallbackItem[]>> => {
    const data = await uploadImage({
      bodyUploadImagesImagesUploadImagesPost: {
        images: files,
      },
    });

    if (!data) {
      return null;
    }

    return data?.images.map((el) => ({ key: el.url, url: el.url }));
  };
