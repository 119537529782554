import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DateFilterOptions } from 'react-datepicker/dist/date_utils';
// eslint-disable-next-line import/no-named-as-default
import ru from 'date-fns/locale/ru';
import moment from 'moment';

import { Maybe, TextField, toaster } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

interface DateInputProps {
  value: [Maybe<Date>, Maybe<Date>];
  onChange: (value: [Maybe<Date>, Maybe<Date>]) => void;
  placeholder: string;
  excludeDateIntervals?: DateFilterOptions['excludeDateIntervals'];
  error?: Maybe<string>;
}
export const DateInput: FC<DateInputProps> = ({
  error,
  excludeDateIntervals,
  onChange,
  placeholder,
  value,
}): JSX.Element => {
  const [curValues, setCurValues] = useState<[Maybe<Date>, Maybe<Date>]>([
    value[0],
    value[1],
  ]);
  const hasValue = !!(curValues[0] && curValues[1]);

  const isDateRangeExcluded = (startDate: Date, endDate: Date) =>
    excludeDateIntervals?.some((interval) => {
      const [intervalStart, intervalEnd] = [
        new Date(interval.start),
        new Date(interval.end),
      ];

      return startDate < intervalEnd && endDate > intervalStart;
    });

  useEffect(() => {
    if (
      curValues[0] &&
      curValues[1] &&
      !isDateRangeExcluded(curValues[0], curValues[1])
    ) {
      onChange(curValues);
    } else if (curValues[0] && curValues[1]) {
      toaster.error({ title: 'Выберите даты в доступном диапазоне' });
      setCurValues([null, null]);
    }
  }, [curValues]);

  useEffect(() => {
    if (!value[0] && !value[1]) {
      setCurValues(value);
    }
  }, [value]);

  return (
    <DatePicker
      onChange={setCurValues}
      startDate={curValues[0]!}
      endDate={curValues[1]!}
      minDate={new Date()}
      selectsRange={true}
      // @ts-ignore
      locale={ru}
      excludeDateIntervals={excludeDateIntervals}
      onCalendarClose={() => !hasValue && setCurValues([null, null])}
      customInput={
        <div className={styles.datePicker}>
          <TextField
            className={styles.datePicker__input}
            value={
              hasValue
                ? `${moment(curValues[0]).format('DD.MM.YYYY')} - ${moment(
                    curValues[1],
                  ).format('DD.MM.YYYY')}`
                : ''
            }
            label={hasValue ? '' : placeholder}
            error={error || undefined}
            readonly
            fullWidth
          />
        </div>
      }
    />
  );
};
