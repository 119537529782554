import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminRemoveHouseModalProps {
  id: number;
}
declare global {
  interface ModalProps {
    removeHouse: AdminRemoveHouseModalProps;
  }
}
export const AdminRemoveHouseModal: FC<
  WithCloseModal<AdminRemoveHouseModalProps>
> = ({ id, onClose }): JSX.Element => {
  const [deleteHouse, { data, error }] =
    sdk.useDeleteHouseHouseDeletePostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Дом успешно удалён' });
      onClose();
    }
    if (error) {
      toaster.error({ title: 'Ошибка удаления дома' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите удалить дом ${id}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              color="danger"
              onClick={() => deleteHouse({ id })}>
              Подтвердить
            </Button>
          }
        />
      }
    />
  );
};
