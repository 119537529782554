import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { registerSchema } from '@entities/Auth';
import {
  Button,
  Checkbox,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { UserCreateWeb } from '@shared/api';
import { ComponentFormProps } from '@shared/types';

import styles from './styles.module.scss';

export type UserCreateWebValues = UserCreateWeb & {
  password_confirm: string;
};

const defaultValues: UserCreateWebValues = {
  email: '',
  password: '',
  password_confirm: '',
};

export interface RegisterFormProps {
  disclaimerSlot?: JSX.Element;
}

export const RegisterForm: FC<
  ComponentFormProps<UserCreateWebValues, RegisterFormProps>
> = ({
  disclaimerSlot,
  errors: apiErrors,
  initState,
  loading,
  onSubmit: formOnSubmit,
  setError,
}) => {
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [data, setData] = useState<UserCreateWebValues>();
  const submitHandler = async (values: UserCreateWebValues) => {
    setData(values);
    if (setError) {
      setError({});
    }
    setFormErrors({});
    try {
      const valuesForSubmit = (await registerSchema(values.password).validate(
        values,
      )) as UserCreateWebValues;

      formOnSubmit(valuesForSubmit);
    } catch (error: any) {
      setFormErrors({ [error.path]: error.errors });
    }
  };
  const {
    errors: currentErrors,
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    setFormErrors,
    values,
  } = useForm<UserCreateWebValues>(submitHandler, {
    ...defaultValues,
    ...initState,
  });
  const errors = { ...currentErrors, ...apiErrors };
  const disabledBtn =
    JSON.stringify(data) === JSON.stringify(values) || !agreeTerms;

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <TextField
        id="email"
        name="email"
        size="large"
        label="Логин"
        value={values.email}
        onChange={onChange}
        error={errors?.email}
        fullWidth
        autoFocus
      />
      <Spacer size={12} />
      <TextField
        id="password"
        name="password"
        type="password"
        size="large"
        label="Пароль"
        initValue={values.password}
        onChange={onChange}
        fullWidth
        error={errors?.password}
      />
      <Spacer size={12} />
      <TextField
        id="password_confirm"
        name="password_confirm"
        type="password"
        size="large"
        label="Повторите пароль"
        initValue={values.password_confirm}
        onChange={onChange}
        fullWidth
        error={errors?.password_confirm}
      />
      <Spacer size={10} />
      <Checkbox
        checked={agreeTerms}
        onChange={(e) => setAgreeTerms(e.target.checked)}
        label="Я принимаю условия Пользовательского соглашения"
        fullWidth
      />
      <Spacer size={8} />
      {disclaimerSlot}
      <Spacer size={8} />
      <Button
        className={styles.form__button}
        size={isMobile ? 'large' : 'default'}
        type="submit"
        disabled={disabledBtn}>
        Зарегистрироваться
      </Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
