import { FC, useMemo, useState } from 'react';

import {
  Checkbox,
  LoaderOverlay,
  TableController,
} from '@house-booking/ui-kit';
import { sdk, Tariff } from '@shared/api';
import { formatAmount, pagination } from '@shared/libs';

const headers = [
  { key: true, value: 'id', width: 60 },
  { value: 'name' },
  { value: 'class' },
  { value: 'cost_per_day' },
  { value: 'check' },
];
const pageSize = 5;

export interface AdminTariffPickerProps {
  initValue: number[];
  onChange(val: number[]): void;
}
export const AdminTariffPicker: FC<AdminTariffPickerProps> = ({
  initValue,
  onChange,
}) => {
  const [value, setValue] = useState<number[]>(initValue);
  const [page, setPage] = useState(1);

  const { data, isFetching } =
    sdk.useListTariffsWithFiltersAndPaginationTariffListGetQuery({
      ...pagination(page, pageSize),
      isActive: true,
    });

  const handleChange = (id: number, val: boolean) => {
    const newValues = val ? [...value, id] : value.filter((el) => el !== id);

    onChange(newValues);
    setValue(newValues);
  };

  const rowTemplate = (tariff: Tariff) => ({
    check: (
      <div className="d-flex-center">
        <Checkbox
          checked={value.includes(tariff.id!)}
          onChange={(e) => handleChange(tariff.id!, e.target.checked)}
        />
      </div>
    ),
    class: tariff.tariff_class || '-',
    cost_per_day: tariff.cost_per_day
      ? String(formatAmount(tariff.cost_per_day))
      : '-',
    id: tariff.id!,
    name: tariff.tariff_name || '-',
  });
  const items = useMemo(
    () => (data?.tariffs || []).map((item) => rowTemplate(item)),
    [data, value],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={isFetching} />
      <TableController
        data={items}
        headers={headers}
        total={data?.edge.total}
        onChangePage={setPage}
        pageSize={pageSize}
        notResetPage
      />
    </div>
  );
};
