import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@house-booking/ui-kit';
import { AdminHousesList } from '@widgets/Admin';

export const AdminHousesView: FC = () => {
  const { navigate } = useNavigate();

  return (
    <div>
      <Head
        title="Список домов"
        rightSlot={
          <Button
            prependIcon={PlusDenseIcon}
            onClick={() => navigate(RouterName.AdminHouseCreate)}>
            Добавить дом
          </Button>
        }
      />
      <AdminHousesList />
    </div>
  );
};
