import { FC, useEffect } from 'react';
import { array, number, object } from 'yup';

import {
  Container,
  FieldLabel,
  MapControl,
  MultiTextField,
  UploadImage,
} from '@/shared';
import { AdminTariffPicker } from '@entities/Admin';
import {
  LoaderOverlay,
  Select,
  Spacer,
  TextField,
  useForm,
} from '@house-booking/ui-kit';
import { HouseCreate, HouseUpdate, sdk } from '@shared/api';
import { handleNumberInput, useAdminUploadImage } from '@shared/libs';
import { getCoords } from '@shared/libs/utils/format';

import styles from './styles.module.scss';

export interface AdminHouseFormProps {
  onChange(values: Required<HouseUpdate>): void;
  onChangeHasError(value: boolean): void;
  initialValues?: HouseCreate;
}
export const AdminHouseForm: FC<AdminHouseFormProps> = ({
  initialValues,
  onChange,
  onChangeHasError,
}) => {
  const uploadImage = useAdminUploadImage();
  const schema = object().shape({
    applicable_tariff_ids: array().min(1, 'Должен быть хотя бы один тариф'),
    area_id: number().min(1, 'Область обязательное поле'),
    block_id: number().min(1, 'Блок обязательное поле'),
    region_id: number().min(1, 'Регион обязательное поле'),
    room: number().required('Комната обязательное поле'),
  });
  const { data, isLoading } =
    sdk.useListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetQuery();

  const areas = data?.areas || [];
  const blocks = data?.blocks || [];
  const regions = data?.regions || [];
  const { errors, setBatchValues, setFormErrors, values } = useForm<
    Partial<HouseUpdate>
  >(
    console.log,
    initialValues || {
      applicable_tariff_ids: [],
      area_id: 0,
      block_id: 0,
      latitude: 50,
      longtitude: 87,
      region_id: 0,
    },
  );

  const handleChange = async () => {
    setFormErrors({});
    onChangeHasError(false);
    try {
      console.log(values);
      onChange(
        // @ts-ignore
        await schema.validate(values as HouseCreate, { abortEarly: false }),
      );
    } catch (error: any) {
      const allErrors = error.inner.reduce((acc: any, curr: any) => {
        acc[curr.path] = curr.errors;

        return acc;
      }, {});

      setFormErrors(allErrors);
      onChangeHasError(true);
    }
    onChange(values as Required<HouseUpdate>);
  };

  useEffect(() => {
    handleChange();
  }, [values]);

  if (isLoading) {
    return <LoaderOverlay show />;
  }

  return (
    <Container className={styles.houseForm}>
      <div className={styles.houseForm__row}>
        <FieldLabel label="Координаты">
          <MapControl
            initValue={getCoords(values)}
            onChange={(val) =>
              setBatchValues({ latitude: val[0], longtitude: val[1] })
            }
          />
        </FieldLabel>
        <FieldLabel label="Фотографии">
          <UploadImage
            initValue={values.image_links || []}
            uploadCallback={uploadImage}
            resolution="1920 * 1080"
            maxCount={12}
            onChange={(val) => setBatchValues({ image_links: val })}
          />
        </FieldLabel>
      </div>
      <div className={styles.houseForm__row}>
        <FieldLabel label="Преимущества">
          <MultiTextField
            initValue={values.services || []}
            onChange={(val) => setBatchValues({ services: val })}
          />
        </FieldLabel>
        <div>
          <div className={styles.houseForm__fields}>
            <FieldLabel label="Регион" error={errors.region_id}>
              <Select
                size="large"
                fullWidth
                initValue={values.region_id}
                items={regions}
                onChange={(value) => setBatchValues({ region_id: +value })}
                disabled={regions.length === 0}
                hideOnSelect
              />
            </FieldLabel>
            <FieldLabel label="Область" error={errors.area_id}>
              <Select
                size="large"
                fullWidth
                initValue={values.area_id}
                items={areas}
                onChange={(value) => setBatchValues({ area_id: +value })}
                disabled={areas.length === 0}
                hideOnSelect
              />
            </FieldLabel>
            <FieldLabel label="Блок" error={errors.block_id}>
              <Select
                size="large"
                fullWidth
                initValue={values.block_id}
                items={blocks}
                onChange={(value) => setBatchValues({ block_id: +value })}
                disabled={blocks.length === 0}
                hideOnSelect
              />
            </FieldLabel>
            <FieldLabel label="Комната" error={errors.room}>
              <TextField
                size="large"
                initValue={values?.room ? String(values.room) : ''}
                onChange={(e) => setBatchValues({ room: +e.target.value })}
                onKeyPress={handleNumberInput}
                fullWidth
              />
            </FieldLabel>
          </div>
          <Spacer size={8} />
          <FieldLabel
            label={`Тарифы (Выбрано штук: ${
              values.applicable_tariff_ids?.length || 0
            })`}
            error={errors.applicable_tariff_ids}>
            <AdminTariffPicker
              initValue={values.applicable_tariff_ids || []}
              onChange={(val) => setBatchValues({ applicable_tariff_ids: val })}
            />
          </FieldLabel>
        </div>
      </div>
    </Container>
  );
};
