import { FC, useState } from 'react';
// @ts-ignore
import { LightgalleryItem } from 'react-lightgallery';
import cx from 'classnames';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Map } from '@/shared';
import { TabItem, Tabs } from '@house-booking/ui-kit';
import { HouseResponse } from '@shared/api';

import styles from './styles.module.scss';

interface HousePreviewProps {
  house: HouseResponse;
  className?: string;
}
enum PreviewTabs {
  Images,
  Map,
}
export const HousePreview: FC<HousePreviewProps> = ({ className, house }) => {
  const [tab, setTab] = useState<PreviewTabs>(PreviewTabs.Images);

  return (
    <div className={cx(styles.preview, className)}>
      <div className={styles.preview__content}>
        <div className={styles.preview__wrapper}>
          {tab === PreviewTabs.Map && <Map markers={[house]} />}
          {tab === PreviewTabs.Images && (
            <Swiper
              className={styles.preview__swiper}
              modules={[Navigation]}
              navigation={{
                nextEl: styles['preview__swiper-next'],
                prevEl: styles['preview__swiper-prev'],
              }}
              slidesPerView={'auto'}>
              {(house.image_links || []).map((image, i) => (
                <SwiperSlide className={styles.preview__slide} key={i}>
                  <LightgalleryItem src={image}>
                    <img className={styles.preview__image} src={image} alt="" />
                  </LightgalleryItem>
                </SwiperSlide>
              ))}
              <div className={styles['preview__swiper-prev']}></div>
              <div className={styles['preview__swiper-next']}></div>
            </Swiper>
          )}
        </div>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem label="Изображения" value={PreviewTabs.Images} />
          <TabItem label="На карте" value={PreviewTabs.Map} />
        </Tabs>
      </div>
    </div>
  );
};
