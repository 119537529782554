import { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { HouseMiniCard, HousesNotFound } from '@entities/House';
import { HousesMap } from '@entities/House/ui/HousesMap/HousesMap';
import { HousesListSideFilter, HousesListTopFilter } from '@features/House';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  LoaderOverlay,
  Maybe,
  Pagination,
  Scroll,
  Spacer,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { getIsoDate, pagination } from '@shared/libs/utils/format';

import { HousesCountInfo } from '../HousesCountInfo/HousesCountInfo';

import styles from './styles.module.scss';

export interface FilterData {
  showAvailable: boolean;
  region: Maybe<number>;
  area: Maybe<number>;
  block: Maybe<number>;
  max_cost_per_day: number | undefined;
  start_date: Maybe<Date>;
  end_date: Maybe<Date>;
}
const defaultFilterData: FilterData = {
  area: null,
  block: null,
  end_date: null,
  max_cost_per_day: undefined,
  region: null,
  showAvailable: false,
  start_date: null,
};

const pageSize = 5;

export const HousesList: FC = (): JSX.Element => {
  const [filterData, setFilterData] = useState<FilterData>(defaultFilterData);
  const [sideFilterVisible, setSideFilterVisible] = useState(false);
  const [wideMap, setWideMap] = useState(false);

  const [page, setPage] = useState<number>(1);
  const baseFilters = {
    area: filterData.area || undefined,
    block: filterData.block || undefined,
    region: filterData.region || undefined,
  };
  const {
    data: dataAll,
    isFetching: isLoadingAll,
    isSuccess: isSuccessAll,
  } = sdk.useListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetQuery(
    {
      ...pagination(page, pageSize),
      ...baseFilters,
    },
  );
  const {
    data: dataAvailable,
    isFetching: isLoadingAvailable,
    isSuccess: isSuccessAvailable,
  } = sdk.useListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetQuery(
    {
      ...pagination(page, pageSize),
      ...baseFilters,
      endDate: filterData.end_date
        ? getIsoDate(filterData.end_date)
        : undefined,
      maxCostPerDay: filterData.max_cost_per_day,
      startDate: filterData.start_date
        ? getIsoDate(filterData.start_date)
        : undefined,
    },
  );
  const isLoading = isLoadingAvailable || isLoadingAll;
  const data = useMemo(() => {
    const emptyData = {
      edge: {
        total: 0,
      },
      houses: [],
    };

    if (filterData.showAvailable) {
      return isSuccessAvailable ? dataAvailable : emptyData;
    }

    return isSuccessAll ? dataAll : emptyData;
  }, [
    dataAll,
    dataAvailable,
    filterData.showAvailable,
    isSuccessAvailable,
    isSuccessAll,
  ]);
  const pageCount = Math.ceil(Number(data?.edge.total) / pageSize);

  useEffect(() => {
    setPage(1);
  }, [filterData]);

  const isNotebook = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  const handleReset = () => {
    setFilterData((state) => ({
      ...defaultFilterData,
      showAvailable: state.showAvailable,
    }));
  };

  return (
    <div className={cx(styles.houses, wideMap && styles['houses--mapWide'])}>
      <div className={styles.houses__controls}>
        <Button size="large" onClick={() => setSideFilterVisible(true)}>
          Фильтр
        </Button>
        <Button
          size="large"
          color="secondary"
          onClick={() => setWideMap(!wideMap)}>
          {wideMap ? 'Список' : 'Карта'}
        </Button>
      </div>
      <div className={styles.houses__wrapper}>
        <HousesListSideFilter
          onChange={(e, key) =>
            setFilterData((prev) => ({ ...prev, [key]: e }))
          }
          onClose={() => setSideFilterVisible(false)}
          onReset={handleReset}
          form={filterData}
          active={sideFilterVisible}
        />
        <div className={styles.houses__items}>
          <HousesCountInfo
            count={data?.edge.total || 0}
            className={styles.houses__info}
          />
          <Spacer size={8} />
          <HousesListTopFilter
            form={filterData}
            onChange={(e, key) => {
              setFilterData((state) => ({ ...state, [key]: e }));
            }}
          />
          <Spacer size={8} />
          <div className={styles.houses__content}>
            <LoaderOverlay show={isLoading} />
            {data?.houses?.length ? (
              <Scroll className={styles.houses__scrollWrapper}>
                <div className={styles.houses__result}>
                  {data?.houses.map((house) => (
                    <HouseMiniCard key={house.id} {...house} />
                  ))}
                </div>
                <Spacer size={8} />
                {pageCount > 1 && (
                  <Pagination
                    pageCount={pageCount}
                    page={page}
                    onChange={setPage}
                  />
                )}
                <Spacer size={8} />
              </Scroll>
            ) : (
              <HousesNotFound onReset={handleReset} />
            )}
          </div>
        </div>
      </div>
      {(wideMap || !isNotebook) && (
        <div className={styles.houses__map}>
          <HousesMap filters={filterData} />
          <Button
            className={styles.houses__button}
            variant="outlined"
            prependIcon={wideMap ? ArrowRightIcon : ArrowLeftIcon}
            onClick={() => setWideMap(!wideMap)}>
            {wideMap ? 'Свернуть карту' : 'Развернуть карту'}
          </Button>
        </div>
      )}
    </div>
  );
};
