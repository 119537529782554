import axios from 'axios';

import { Maybe } from '@house-booking/ui-kit';
import {
  UploadDocumentImagesImagesUploadDocsImagesPostApiArg,
  UploadDocumentImagesImagesUploadDocsImagesPostApiResponse,
} from '@shared/api';
import { ACCESS_TOKEN_COOKIE_KEY } from '@shared/const';
import { getCookie } from '@shared/libs';
import { UploadCallbackItem } from '@shared/ui/UploadImage/UploadImage';

export const uploadDocs = (
  params: UploadDocumentImagesImagesUploadDocsImagesPostApiArg,
): Promise<UploadDocumentImagesImagesUploadDocsImagesPostApiResponse> => {
  const formData = new FormData();

  if (params.bodyUploadDocumentImagesImagesUploadDocsImagesPost.images) {
    for (const el of params.bodyUploadDocumentImagesImagesUploadDocsImagesPost
      .images) {
      formData.append('images', el);
    }
  }

  // @ts-ignore
  return axios
    .post('/api/api_v1/images/upload_docs_images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${getCookie(ACCESS_TOKEN_COOKIE_KEY)}`,
      },
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};
export const useUploadDocs =
  () =>
  async (files: File[]): Promise<Maybe<UploadCallbackItem[]>> => {
    const data = await uploadDocs({
      bodyUploadDocumentImagesImagesUploadDocsImagesPost: {
        images: files,
      },
    });

    if (!data) {
      return null;
    }

    return files.map((file, idx) => ({
      key: data.images[idx].url,
      url: URL.createObjectURL(file),
    }));
  };
