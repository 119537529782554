import { FC, PropsWithChildren } from 'react';

import { Header } from '../Header';

import styles from './styles.module.scss';

export const BaseLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => (
  <div className={styles.base}>
    <Header />
    <main className={styles.base__main}>{children}</main>
  </div>
);
