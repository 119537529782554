import { FC } from 'react';

import { AuthLogin } from '@widgets/Auth';

import styles from './styles.module.scss';

export const AuthView: FC = () => (
  <div className={styles.authView}>
    <AuthLogin />
  </div>
);
